import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { Form, Space } from 'antd'
import { ProfileTable } from '@/components/Table'
import { DownArrow } from '@/assets'
import { Button, ProfileButton } from '@/components/Button'
import Module from '@/components/Module'
import InputBox from '@/components/InputBox'
import type { TableProps } from 'antd'
import { useHistory } from 'react-router-dom'
import { CurrencyAsset, WalletApi } from '@/services/wallet'
import useQuery from '@/hooks/useQuery'
import Pagination from '@/components/Pagination'
import { decryptData } from '@/common/dataConvert'
import { getMediaSrc } from '@/utils/utils'
import { ENTER_CODE, handleKeyDown } from '@/common/event'
import Checkbox from '@/components/Checkbox'
import { ListResponse } from '@/services/common'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB3_TYPE } from '@/constants/user'

const { SearchInput } = InputBox

const PAGE_SIZE = 10

type Props = {
  showAsset: boolean
}

const AssetsDetail: React.FC<Props> = ({ showAsset }) => {
  const { t } = useTranslation()
  const [sorts, setSorts] = useState<string[]>([])
  const { info } = useUserInfo()

  const { data: supportList = [] } = useQuery(WalletApi.getCoinSupportList)

  const [form] = Form.useForm()

  const [pageNo, setPageNo] = useState(1)

  const { data: originAssetList, refresh } = useQuery(() => {
    const { coinCode, hide } = form.getFieldsValue()
    return WalletApi.getAssetList({
      pageSize: PAGE_SIZE,
      pageNo,
      coinCode,
      hide,
      hideAmount: hide ? 1 : undefined,
      sorts: sorts.length > 0 ? sorts : undefined
    })
  }, [sorts, pageNo])

  function handleRefresh() {
    if (pageNo === 1) {
      refresh()
    } else {
      setPageNo(1)
    }
  }

  const listData = useMemo<ListResponse<{ records: CurrencyAsset[] }>>(() => {
    if (originAssetList) {
      return decryptData(originAssetList)
    }
  }, [originAssetList])

  const history = useHistory()
  function to(path: string) {
    history.push(path)
  }

  function handleClear() {
    form.setFieldValue('coinCode', '')
    refresh()
  }

  const columns: TableProps<CurrencyAsset>['columns'] = [
    {
      title: t('wallet.currency'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      showSorterTooltip: false,
      sorter: {
        multiple: 3
      },
      render(val, record) {
        const src = getMediaSrc(record.coverImage)
        return (
          <div className={styles.kindCtn}>
            <img src={src} className={styles.currencyIcon} />
            <div className={styles.texts}>
              <div className={styles.kind}>{record.name}</div>
              <div>{record.code}</div>
            </div>
          </div>
        )
      }
    },
    {
      title: t('normal.amount'),
      dataIndex: 'availableAmount',
      key: 'availableAmount',
      showSorterTooltip: false,
      sorter: {
        multiple: 3
      },
      render(val, record) {
        return (
          <>
            <div>{showAsset ? record.available : '****'}</div>
            <div className={styles.amount}>
              {showAsset ? `$${record.availableAmount}` : '****'}
            </div>
          </>
        )
      }
    },
    // {
    //   title: t('normal.price'),
    //   dataIndex: 'availableAmount',
    //   key: 'availableAmount',
    //   sorter: {
    //     multiple: 3
    //   }
    // },
    {
      title: '',
      dataIndex: 'code',
      key: 'code',
      width: 200,
      render(val, record) {
        if (record.code === 'M Point') {
          return null
        }
        if (record.code === 'Diamond') {
          return (
            <Button
              className={styles.tableBtn}
              onClick={() => to(`/profile/diamond-recharge`)}
            >
              {t('wallet.assets.depositDiamond')}
            </Button>
          )
        }
        if (info?.type === WEB3_TYPE) {
          return null
        }
        const showDeposit =
          supportList.find((item) => item.coinCode === record.code)
            ?.depositEnable === 1
        const showWithdraw =
          supportList.find((item) => item.coinCode === record.code)
            ?.withdrawEnable === 1
        return (
          <div className={styles.operate}>
            {showDeposit && (
              <Button
                className={styles.tableBtn}
                onClick={() => to(`/profile/recharge?coinCode=${record.code}`)}
              >
                {t('wallet.assets.deposit')}
              </Button>
            )}
            {showWithdraw && (
              <Button
                className={styles.tableBtn}
                onClick={() => to(`/profile/withdraw?coinCode=${record.code}`)}
              >
                {t('wallet.assets.withdraw')}
              </Button>
            )}
            <img
              src={DownArrow}
              className={styles.toCurrencyDetailBtn}
              onClick={() =>
                to(
                  `/profile/currency-detail?coinCode=${record.code}&coinId=${record.coinId}`
                )
              }
            />
          </div>
        )
      }
    }
  ]

  return (
    <Module className={styles.assetsDetail}>
      <Form form={form} className={styles.form}>
        <div className={styles.left}>
          <div className={styles.searchTitle}>
            {t('wallet.assets.myAssets')}
          </div>
          <Space size={8}>
            <Form.Item noStyle name="hide" valuePropName="checked">
              <Checkbox onChange={handleRefresh} />
            </Form.Item>
            <span
              className={styles.hideTip}
            >{`${t('wallet.assets.hideAssets')}< 1 USDT`}</span>
          </Space>
        </div>
        <Space>
          <Form.Item noStyle name="coinCode">
            <SearchInput
              placeholder={t('normal.search')}
              onKeyDown={handleKeyDown(ENTER_CODE, handleRefresh)}
              onSearch={handleRefresh}
              className={styles.searchInput}
            />
          </Form.Item>
          <ProfileButton type="button" onClick={handleClear}>
            {t('normal.reset')}
          </ProfileButton>
        </Space>
      </Form>
      <div className={styles.divider}></div>
      <Empty data={listData?.records ?? []}>
        <ProfileTable
          className={styles.table}
          rowKey={'code'}
          dataSource={listData?.records ?? []}
          columns={columns}
          pagination={false}
          onChange={(pagination, filters, sorter: any) => {
            const sorts = Array.isArray(sorter) ? sorter : [sorter]
            const filterSorts = sorts.filter((sort) => sort.order !== undefined)
            setSorts(
              filterSorts.map(({ order, columnKey }) => {
                return `${columnKey}:${order === 'ascend' ? 'asc' : 'desc'}`
              })
            )
          }}
        />
        <div className={styles.paginationCtn}>
          <Pagination
            total={+(listData?.total ?? 0)}
            showSizeChanger={false}
            onChange={(page) => setPageNo(page)}
            current={pageNo}
            pageSize={PAGE_SIZE}
          />
        </div>
      </Empty>
    </Module>
  )
}

export default React.memo(AssetsDetail)
