import { DiamondIcon } from '@/assets'

export const PERSONAL = 'personal'
export const ASSETS = 'assets'
export const NFT_MANAGE = 'nft-manage'
export const ITEMS_MANAGE = 'items-manage'
export const ORDER = 'order'
export const WITHDRAW = 'withdraw'
export const POINT = 'point'
export const ORDER_NFT = 'order-nft'
export const OTHER = 'other'
export const SETTING = 'setting'
export const WITHDRAW_MANAGE = 'withdrawManage'
export const WITHDRAW_ADD_ADDRESS = 'withdrawAddAddress'
export const DAZZLE_MANAGE = 'dazzleManage'
export const NFT_TRANSFER_RECORDS = 'nft-transfer-records'
export const DIAMOND_RECHARGE_RECORD = 'diamond-recharge-record'
export const DIAMOND_CONSUME_RECORD = 'diamond-consume-record'
export const FQA = 'fqa'

export const MODULES = [
  {
    name: 'personal.personal',
    key: PERSONAL,
    path: '/profile/base-info'
  },
  {
    name: 'normal.asset',
    key: ASSETS,
    path: '/profile/assets'
  },
  {
    name: 'dazzle.myCreations',
    key: DAZZLE_MANAGE,
    path: '/profile/video-manage'
  },
  {
    name: 'profile.myNFT',
    key: NFT_MANAGE,
    path: '/profile/nft'
  },
  {
    name: 'profile.myItems',
    key: ITEMS_MANAGE,
    path: '/profile/items'
  },
  {
    name: 'profile.orderRecord',
    key: ORDER,
    path: '/profile/currency-record',
    web3Path: '/profile/diamond-recharge-record',
    children: [
      {
        name: 'wallet.depositAndWithdrawalRecords',
        key: WITHDRAW,
        path: '/profile/currency-record'
      },
      {
        name: 'wallet.diamondRechargeRecord',
        key: DIAMOND_RECHARGE_RECORD,
        path: '/profile/diamond-recharge-record'
      },
      {
        name: 'wallet.diamondConsumptionRecord',
        key: DIAMOND_CONSUME_RECORD,
        path: '/profile/diamond-consume-record'
      },
      {
        name: 'profile.nftRecord',
        key: ORDER_NFT,
        path: '/profile/nft-record'
      },
      {
        name: 'profile.otherRecord',
        key: OTHER,
        path: '/profile/other-record'
      },
      {
        name: 'profile.nftTransferRecord',
        key: NFT_TRANSFER_RECORDS,
        path: '/profile/nft-transfer-record'
      },
      {
        name: 'profile.pointRecord',
        key: POINT,
        path: '/profile/point-record'
      }
    ]
  },
  {
    name: 'help.helpAndReport',
    key: FQA,
    path: '/profile/help-and-feedback'
  },
  {
    name: 'profile.setting',
    key: SETTING,
    path: '/profile/setting'
  }
]

export const DIAMOND_ICON = DiamondIcon
export const USDT_ICON =
  'https://static.meet48.xyz/image/banner/2024/08/500401740818944001_aff7bdbe-1dc8-46d1-a95f-8a1f001346d8.png'
export const BNB_ICON =
  'https://static.meet48.xyz/image/banner/2024/08/500404238241763329_eeb4c770-3a0b-48c4-88a4-c9613c5d8796.png'
