import React from 'react'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB2_TYPE, WEB3_TYPE } from '@/constants/user'
import Web2NFT from './Web2'
import Web3NFT from './Web3'

const NFT: React.FC = () => {
  const { info } = useUserInfo()
  const isWeb2 = info?.type === WEB2_TYPE
  const isWeb3 = info?.type === WEB3_TYPE

  return (
    <div>
      {isWeb2 && <Web2NFT />}
      {isWeb3 && <Web3NFT />}
    </div>
  )
}

export default React.memo(NFT)
