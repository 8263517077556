import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import { DownArrow, Eye, EyeInvisible } from '@/assets'
import { useTranslation } from 'react-i18next'
import { Option } from '@/common/types'
import { WalletApi } from '@/services/wallet/api'

type Props = {
  showAsset: boolean
  toggle: () => void
  totalUsd: string
}

const Total: React.FC<Props> = ({ totalUsd, showAsset, toggle }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<Option[]>([])
  const [currentAsset, setCurrentAsset] = useState<Option>()
  const [totalU, setTotalU] = useState<string>('0')
  const [data, setData] = useState<
    {
      availableValuation: string
      frozenValuation: string
      toCoinCode: string
      toCoinImage: string
    }[]
  >([
    {
      availableValuation: '0',
      frozenValuation: '0',
      toCoinCode: 'USDT',
      toCoinImage: ''
    }
  ])

  const handleInit = async () => {
    const res = await WalletApi.getUSDConvert(Number(totalUsd))
    if (res.data.code === 0) {
      setTotalU(res.data.data.availableUSDValuation)
      setData([...res.data.data.quotes])
    }
  }

  useEffect(() => {
    if (totalUsd) {
      handleInit()
    }
  }, [totalUsd])

  useEffect(() => {
    if (data.length > 0) {
      const _options = data.map((item) => {
        return {
          label: item.toCoinCode,
          value: item.availableValuation
        }
      })
      setOptions(_options)
      const usdt = _options.find((item) => item.label === 'USDT')
      setCurrentAsset(usdt || _options[0])
    }
  }, [data])

  async function handleSelect(label: string) {
    setCurrentAsset(options.find((o) => o.label === label))
  }

  return (
    <div className={styles.info}>
      <div className={styles.titleCtn}>
        <span className={styles.title}>{t('wallet.assets.estTotalValue')}</span>
        <img
          src={showAsset ? Eye : EyeInvisible}
          className={styles.eyeIcon}
          onClick={toggle}
        />
      </div>
      <div>
        <span className={styles.balance}>
          {showAsset ? (currentAsset?.value ?? 0) : '****'}
        </span>
        {options.length > 0 && (
          <div className={styles.unitCtn}>
            <span>{currentAsset?.label}</span>
            <img src={DownArrow} className={styles.arrowIcon} />
            <div className={styles.list}>
              {options.map((option) => {
                return (
                  <div
                    key={option.label}
                    onClick={() => handleSelect(option.label)}
                    className={styles.item}
                  >
                    {option.label}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div className={styles.dollar}>{showAsset ? `≈$${totalU}` : '****'}</div>
    </div>
  )
}

export default React.memo(Total)
