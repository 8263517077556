import React, { useState } from 'react'
import styles from './style.module.less'
import { TextareaProps } from '@/common/types'
import classNames from 'classnames'

type Props = TextareaProps & {
  limit?: number
}

const Textarea: React.FC<Props> = ({
  className,
  limit = Infinity,
  onChange,
  ...others
}) => {
  const [value, setValue] = useState('')
  return (
    <div className={styles.ctn}>
      <textarea
        className={classNames(styles.textarea, className)}
        {...others}
        maxLength={limit}
        onChange={(e) => {
          setValue(e.target.value)
          onChange?.(e)
        }}
      />
      <div className={styles.tip}>
        ({value.length}/{limit})
      </div>
    </div>
  )
}

export default React.memo(Textarea)
