import { useEffect } from 'react'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB3_TYPE } from '@/constants/user'
import { errorNotice } from '@/common/tip'
import useUSDT from './useUsdt'
import useBNB from './useBNB'
import { SupportToken } from '@/services/wallet'
import { useChainId } from 'wagmi'

export function useCoinBalance(list: SupportToken[] = []) {
  const { info } = useUserInfo()
  const chainId = useChainId()

  const usdtContractAddress = list.find(
    (item) => item.coinCode === 'USDT'
  )?.contractAddress

  const {
    data: usdt,
    refetch: usdtRefetch,
    isLoading: isLoadingUsdt
  } = useUSDT(usdtContractAddress)
  const { data: bnb, refetch: bnbRefetch, isLoading: isLoadingBnb } = useBNB()

  async function refetch() {
    usdtRefetch()
    bnbRefetch()
  }

  useEffect(() => {
    if (info?.type === WEB3_TYPE) {
      refetch()
    }
  }, [info?.type, chainId])

  function handleRefetch() {
    const start = +new Date()
    const timer = setInterval(async () => {
      try {
        if (start + 5000 > +new Date()) {
          await refetch()
          clearInterval(timer)
        } else {
          clearInterval(timer)
        }
      } catch (error: any) {
        errorNotice(error)
      }
    }, 1000)
  }

  return {
    usdt,
    bnb,
    refetch: handleRefetch,
    isLoading: isLoadingUsdt || isLoadingBnb
  }
}
