import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { getLocalItem } from './common/storage'
import { LANGUAGE } from './constants/keys'

function getInitLang() {
  const lang = getLocalItem(LANGUAGE)
  return lang || 'en_US'
}

// https://www.i18next.com/overview/configuration-options
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: `./locales/{{lng}}-${process.env.hash}.json`
    },
    react: {
      useSuspense: true
    },
    load: 'languageOnly',
    // http://www.lingoes.cn/zh/translator/langcode.htm
    supportedLngs: ['en_US', 'zh_TW'],
    fallbackLng: 'en_US',
    // defaultNS: 'en',
    lng: getInitLang(),
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
