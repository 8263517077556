import { decryptData } from '@/common/dataConvert'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import { useMemo } from 'react'

export type DiamondPointInfo = {
  available: string //数量
  availableAmount: string // 美刀
  code: string
  coverImage: string
  name: string
}

export default function useDiamondPoints() {
  const { data, loading } = useQuery(() => WalletApi.getDiamondPoints(), [])

  const decryptResult: DiamondPointInfo[] = useMemo(() => {
    return data ? decryptData(data) : null
  }, [data])

  const diamond = decryptResult?.find((item: any) => item.code === 'Diamond')
  const points = decryptResult?.find((item: any) => item.code === 'M Point')

  const diamondAmount = useMemo(() => diamond?.available || '0', [diamond])
  const pointsAmount = useMemo(() => points?.available || '0', [points])
  const diamondUSDValue = useMemo(
    () => Number(diamond?.availableAmount || 0),
    [diamond]
  )
  const pointsUSDValue = useMemo(
    () => Number(points?.availableAmount || 0),
    [points]
  )

  return {
    diamondAmount,
    pointsAmount,
    diamondUSDValue,
    pointsUSDValue,
    loading
  }
}
