import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  label: string
  className?: string
}

const LabelFormItem: React.FC<PropsWithChildren<Props>> = ({
  label,
  children,
  className = ''
}) => {
  return (
    <div className={classNames(styles.labelFormItem, className)}>
      <div className={styles.label}>{label}</div>
      {children}
    </div>
  )
}

export default React.memo(LabelFormItem)
