import React, { useMemo, useCallback } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { NFT_MANAGE } from '@/constants/profile'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { WarningIcon } from '@/assets'
import { getLangData, getMediaSrc, getSearchParams } from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import { UserAPI } from '@/services/userAPI'
import { useTranslation } from 'react-i18next'
import { WalletApi } from '@/services/wallet'
import { useNFTBalance } from '@/hooks/web3/useNFTBalance'
import { useUserInfo } from '@/store/useUserInfo'
import { WalletAddress } from '@/common/types'
import { useChainId } from 'wagmi'
import Share from '@/components/Share'
import { useBaseConfig } from '@/store/useBaseConfig'

const NFTDetail: React.FC = () => {
  const params = getSearchParams()
  const history = useHistory()
  const { t } = useTranslation()
  const { info } = useUserInfo()
  const address = info?.address
  const chainId = useChainId()
  const {
    baseConfig: { supportChainInfos }
  } = useBaseConfig()

  const { data: supportNFTList } = useQuery(
    () => WalletApi.getNFTSupportList(chainId),
    [chainId]
  )

  const { data: extendListData } = useQuery(
    () =>
      UserAPI.getNFTExtendInfo([
        {
          nftId: Number(params.id),
          tokenId: Number(params.tokenId)
        }
      ]),
    [params.id, params.tokenId]
  )

  const dataWithoutBalance = useMemo(() => {
    const extendData = extendListData?.[0]
    if (!extendData) return null
    const supportNFT = supportNFTList?.find(
      (item) =>
        item.nftId === extendData.nftId && item.id === extendData.tokenId
    )
    return {
      ...supportNFT,
      ...extendData
    }
  }, [extendListData, supportNFTList])

  const { data: balanceData } = useNFTBalance({
    nftId: Number(params.id),
    contractAddress: dataWithoutBalance?.contractAddress,
    address: address as WalletAddress,
    tokenType: dataWithoutBalance?.tokenType
  })

  const data = useMemo(() => {
    if (!dataWithoutBalance) return null

    if (balanceData && BigInt(balanceData.toString()) > 0n) {
      return {
        ...dataWithoutBalance,
        quantityHeld: balanceData.toString()
      }
    }
    return { ...dataWithoutBalance, quantityHeld: '-' }
  }, [balanceData, dataWithoutBalance])

  const name = useMemo(() => getLangData(data?.goodsNameI18n), [data])

  const src = useMemo(() => getMediaSrc(data?.showImageUrl), [data])

  const chainName = useMemo(() => {
    if (!data) return '-'
    return data.tokenId === 0 ? t('profile.onChain') : data.chainFullName
  }, [data, t])

  const chainIcon = supportChainInfos?.find(
    (chain) => chain.chainCode === data?.chainCode
  )?.chainIcon

  return (
    <Page navigator={NFT_MANAGE}>
      <Module>
        <div className={styles.header}>
          <GoBackTitle
            title={name}
            onClick={() => history.push('/profile/nft')}
          />
        </div>
        <div className={styles.detail}>
          <div className={styles.posterCtn}>
            {chainIcon && (
              <img
                src={getMediaSrc(chainIcon)}
                className={styles.locationIcon}
              />
            )}
            <div className={styles.box}>
              {src && <img className={styles.poster} src={src} alt={name} />}
            </div>
          </div>
          <div className={styles.infoCtn}>
            <div className={styles.labelWrapper}>
              <div className={styles.label}>{t('profile.basicInfo')}</div>
              <Share position="bottom-left" />
            </div>
            <div className={styles.title}>{name}</div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.quantity')}</div>
              <div>{data?.quantityHeld ?? '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.network')}</div>
              <div>{chainName}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.tokenId')}</div>
              <div>{params.tokenId}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.contractAddress')}</div>
              <div>{data?.contractAddress ?? '-'}</div>
            </div>
          </div>
        </div>
        <div className={styles.tipCtn}>
          <img src={WarningIcon} className={styles.warningIcon} />
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: getLangData(data?.goodsDescribeI18n)
            }}
          ></div>
        </div>
      </Module>
    </Page>
  )
}

export default React.memo(NFTDetail)
