import React, { useEffect } from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { getSearchParams } from '@/utils/utils'
import useToLogin from '@/hooks/useToLogin'

const NotConnectButton: React.FC = () => {
  const { t } = useTranslation()

  const { onLogin, onRegister } = useToLogin()

  useEffect(() => {
    const params = getSearchParams()
    if (params.login) {
      onLogin()
    }
    if (params.register) {
      onRegister()
    }
  }, [])

  return (
    <div className={styles.notConnectButton}>
      <Button className={styles.btn} onClick={onLogin} borderOnly>
        {t('login.loginUpperCase')}
      </Button>
      <Button className={styles.btn} onClick={onRegister}>
        {t('login.registerUpperCase')}
      </Button>
    </div>
  )
}

export default NotConnectButton
