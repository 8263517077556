import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { HookOnError } from '@/common/types'
import { NFTRecord } from '@/services/shopAPI'
import useCheckAccount from './useCheckAccount'
import { useEffect } from 'react'

const abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nftContract',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'salt',
        type: 'uint256'
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      },
      {
        internalType: 'bytes32',
        name: 'proof',
        type: 'bytes32'
      }
    ],
    name: 'web3TransferERC1155Proof',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export function useProof(onError: HookOnError) {
  const { data, writeContract } = useWriteContract()
  const checkAccount = useCheckAccount()

  const { isLoading, isSuccess, error } = useWaitForTransactionReceipt({
    hash: data
  })

  useEffect(() => {
    if (error) {
      onError(error)
    }
  }, [error])

  async function writeHandle(params: NFTRecord) {
    if (!params.proof) {
      return
    }
    try {
      await checkAccount()
      const { contract, nftContract, nftId, blockChainMount, salt, proof } =
        params
      await writeContract(
        {
          address: contract!,
          abi,
          functionName: 'web3TransferERC1155Proof',
          args: [nftContract, nftId, +blockChainMount, salt, '0x', proof]
        },
        {
          onError
        }
      )
    } catch (e) {
      onError(e)
    }
  }

  return { hash: data, isLoading, isSuccess, writeHandle }
}
