import React, { useState } from 'react'
import Total from './Total'
import AssetsDetail from './AssetsDetail'

const Web2Assets: React.FC = () => {
  const [showAsset, setShowAsset] = useState(true)
  function toggle() {
    setShowAsset((s) => !s)
  }

  return (
    <>
      <Total showAsset={showAsset} toggle={toggle} />
      <AssetsDetail showAsset={showAsset} />
    </>
  )
}

export default React.memo(Web2Assets)
