import { decryptData } from '@/common/dataConvert'
import { CommonApi } from '@/services/commonAPI'
import { create } from 'zustand'

export type SupportChain = {
  chainId: string
  chainCode: string
  chainName: string
  chainIcon: string
}

export type BaseConfig = {
  metamaskLoginSwitch: boolean
  okexLoginSwitch: boolean
  appleLoginSwitch: boolean
  chargeAndWithdrawSwitch: boolean
  agentSwitch: boolean
  uploadVideoSwitch: boolean
  achSwitch: boolean
  defaultQChatChannelBackgroundImg: string[]
  idolAnswerTextLengthLimit: number
  appAssetSwitch: boolean
  supportChainInfos: SupportChain[]
}

type State = { baseConfig: Partial<BaseConfig> }

type Actions = {
  getBaseConfig: () => void
}

const DEFAULT_INFO: Partial<BaseConfig> = {
  achSwitch: false,
  supportChainInfos: []
}

const useBaseConfig = create<State & Actions>((set) => ({
  baseConfig: DEFAULT_INFO,
  getBaseConfig: async () => {
    const res = await CommonApi.getBaseConfig()
    if (res.data.code === 0) {
      set({ baseConfig: decryptData(res.data.data) })
    }
  }
}))

export { useBaseConfig }
