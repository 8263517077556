import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { TitleModule } from '../../components'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import Tabs from '@/components/Tabs'
import type { TabsProps } from 'antd'
import DiamondRechargeTable from '../../components/DiamondRechargeTable'
import { PaymentApi } from '@/services/payment'

const Table: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [rechargeType, setRechargeType] = useState('1')

  const { data: buyDiamondData } = useQuery(() =>
    PaymentApi.getOrders({
      pageNo: 1,
      pageSize: 5,
      type: 3
    })
  )

  const { data: cryptoSwapData } = useQuery(() =>
    PaymentApi.getOrders({
      pageNo: 1,
      pageSize: 5,
      type: 4
    })
  )

  const { data: buyDiamondDS = [] } = buyDiamondData ?? {}
  const { data: cryptoSwapDS = [] } = cryptoSwapData ?? {}

  const formTabs: TabsProps['items'] = [
    {
      key: '1',
      label: t('wallet.assets.buyDiamond'),
      children: (
        <Empty data={buyDiamondDS}>
          <DiamondRechargeTable dataSource={buyDiamondDS} />
        </Empty>
      )
    },
    {
      key: '2',
      label: t('wallet.assets.cryptoSwap'),
      children: (
        <Empty data={cryptoSwapDS}>
          <DiamondRechargeTable dataSource={cryptoSwapDS} />
        </Empty>
      )
    }
  ]

  return (
    <TitleModule
      title={t('wallet.assets.depositDiamondHistory')}
      onClickMore={() => history.push('/profile/diamond-recharge-record')}
    >
      <Tabs
        items={formTabs}
        activeKey={rechargeType}
        onChange={(key) => setRechargeType(key)}
      />
    </TitleModule>
  )
}

export default React.memo(Table)
