import { useState, useEffect, useCallback } from 'react'
import { WalletApi } from '@/services/wallet'
import { getQrCode } from '@/utils/utils'

interface DepositAddressResult {
  data?: {
    address: string
  }
  qrCode?: string
  loading: boolean
  error?: Error
}

export function useDepositAddress(chainCode: string): DepositAddressResult {
  const [result, setResult] = useState<DepositAddressResult>({
    loading: true
  })

  const fetchDepositAddress = useCallback(async () => {
    if (!chainCode) {
      setResult({ loading: false })
      return
    }

    try {
      // 获取存款地址
      const addressResponseData =
        await WalletApi.getNFTDepositAddress(chainCode)
      const addressData = addressResponseData.data?.data
      // 生成二维码
      const qrCodeSrc = addressData
        ? await getQrCode(addressData.address)
        : undefined

      setResult({
        data: addressData,
        qrCode: qrCodeSrc,
        loading: false
      })
    } catch (error) {
      setResult({
        loading: false,
        error: error instanceof Error ? error : new Error('Unknown error')
      })
    }
  }, [chainCode])

  useEffect(() => {
    fetchDepositAddress()
  }, [fetchDepositAddress])

  return result
}

export const useNFTDeposit = (chainCode: string) => {
  const { data, qrCode } = useDepositAddress(chainCode)
  return {
    address: data?.address || `Sorry we haven't support ${chainCode}`,
    qrCode
  }
}
