import React from 'react'
import styles from './style.module.less'
import Module from '@/components/Module'
import classNames from 'classnames'
import { MODULES } from '@/constants/profile'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  DIAMOND_RECHARGE_RECORD,
  DIAMOND_CONSUME_RECORD,
  ORDER_NFT,
  OTHER,
  POINT
} from '@/constants/profile'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB3_TYPE } from '@/constants/user'

type Props = {
  navigator?: string
}

const WEB3_RECORD_TABS = [
  DIAMOND_RECHARGE_RECORD,
  DIAMOND_CONSUME_RECORD,
  ORDER_NFT,
  OTHER,
  POINT
]

const Navigator: React.FC<Props> = ({ navigator = '' }) => {
  const [first, second] = navigator.split('/')
  const history = useHistory()
  const { t } = useTranslation()

  const { info } = useUserInfo()
  const isWeb3 = info?.type === WEB3_TYPE

  function to(path = '/profile/base-info') {
    history.push(path)
  }

  function getPath(n: any) {
    return (isWeb3 ? n.web3Path : n.path) || n.path
  }

  return (
    <Module className={styles.navigator}>
      {MODULES.map((navigate) => {
        const isActive = first === navigate.key
        return (
          <div
            key={navigate.key}
            className={classNames(
              styles.navigate,
              isActive && styles.activeNavigate
            )}
            onClick={() => to(getPath(navigate))}
          >
            <div className={styles.name}>{t(navigate.name)}</div>
            <div
              className={classNames(
                styles.children,
                isActive && navigate.children && styles.show
              )}
            >
              {navigate.children?.map((n) => {
                const isActive = second === n.key
                if (
                  info?.type === WEB3_TYPE &&
                  !WEB3_RECORD_TABS.includes(n.key)
                ) {
                  return null
                }
                return (
                  <div
                    key={n.key}
                    className={classNames(
                      styles.name,
                      styles.subNavigate,
                      isActive && styles.activeSubNavigate
                    )}
                    onClick={(e) => {
                      e.stopPropagation()
                      to(n.path)
                    }}
                    title={t(n.name)}
                  >
                    {t(n.name)}
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </Module>
  )
}

export default React.memo(Navigator)
