import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { TextButton } from '@/components/Button'
import Input from '@/components/Input'
import Dropdown from '@/components/Dropdown'
import { DarkFilledCloseIcon, DownArrow } from '@/assets'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

type Coin = {
  code: string
  icon: string
}

type Props = {
  label: string
  extra?: React.ReactNode
  hasClear?: boolean
  hasMax?: boolean
  list?: Coin[]
  placeholder?: string
  code?: string
  codeChange?: (code: string) => void
  value?: string
  onChange?: (val: string) => void
  onMax?: () => void
  disabled?: boolean
}

const AssetInput: React.FC<Props> = (props) => {
  const {
    label,
    hasClear = true,
    hasMax = false,
    list = [],
    extra,
    placeholder,
    onChange,
    code,
    codeChange,
    value,
    onMax,
    disabled = false
  } = props

  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  function close() {
    setOpen(false)
  }

  const currentCurrency = useMemo(() => {
    return list.find((item) => item.code === code)
  }, [code, list])

  return (
    <div className={styles.assetInput}>
      <div className={styles.header}>
        <div className={styles.label}>{label}</div>
        {extra && <div className={styles.extra}>{extra}</div>}
      </div>
      <div className={styles.main}>
        <div className={styles.inputCtn}>
          <Input
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
          />
          {hasClear && (
            <img
              src={DarkFilledCloseIcon}
              className={styles.clearIcon}
              onClick={() => onChange?.('')}
            />
          )}
          {hasMax && (
            <TextButton
              className={styles.maxBtn}
              onClick={onMax}
              disabled={disabled}
            >
              {t('wallet.assets.max')}
            </TextButton>
          )}
        </div>
        <div className={styles.assetCtn}>
          {list.length > 1 ? (
            <Dropdown
              onOpenChange={(open) => setOpen(open)}
              trigger={['click']}
              open={open}
              dropdownRender={() => {
                return (
                  <div className={styles.list}>
                    {list.map((o) => {
                      return (
                        <div
                          key={o.code}
                          className={styles.option}
                          onClick={() => {
                            codeChange?.(o.code)
                            close()
                          }}
                        >
                          <img src={o.icon} className={styles.coinImg} />
                          <div
                            className={styles.coinCode}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {o?.code === 'Diamond'
                              ? t('wallet.assets.diamond')
                              : o?.code}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              }}
            >
              <div className={styles.select} onClick={() => setOpen((o) => !o)}>
                <img src={currentCurrency?.icon} className={styles.coinImg} />
                <div className={styles.coinCode}>{currentCurrency?.code}</div>
                {list.length > 1 && (
                  <img
                    src={DownArrow}
                    className={classNames(
                      styles.arrow,
                      open && styles.openedArrow
                    )}
                  />
                )}
              </div>
            </Dropdown>
          ) : (
            <div className={styles.select}>
              <img src={currentCurrency?.icon} className={styles.coinImg} />
              <div
                className={styles.coinCode}
                style={{ textTransform: 'capitalize' }}
              >
                {currentCurrency?.code === 'Diamond'
                  ? t('wallet.assets.diamond')
                  : currentCurrency?.code}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(AssetInput)
