import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type InputNumberProps = {
  value: string
  onChange: (val: string) => void
  className?: string
  limit?: number
  min?: number
}

const InputNumber: React.FC<InputNumberProps> = ({
  onChange,
  value,
  className = '',
  limit = Infinity,
  min = 0
}) => {
  const changeHandle = (val: string) => {
    if (!val) {
      onChange(`${min}`)
    }
    const next = Number.parseInt(val)

    let num = Number.isInteger(next) ? next : min
    num = Math.max(min, num)
    num = Math.min(num, limit)

    onChange(`${num}`)
  }

  const minus = () => {
    const num = +value
    onChange(`${Math.max(min, num - 1)}`)
  }

  const add = () => {
    const num = +value
    onChange(`${Math.min(limit, num + 1)}`)
  }

  return (
    <div className={classNames(styles.inputNumber, className)}>
      <div className={styles.btn} onClick={minus}>
        -
      </div>
      <input
        type="text"
        value={value}
        onChange={(e) => changeHandle(e.target.value)}
        className={styles.input}
      />
      <div className={styles.btn} onClick={add}>
        +
      </div>
    </div>
  )
}

export default React.memo(InputNumber)
