import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import { Page, TitleModule } from '../components'
import { NFT_MANAGE } from '@/constants/profile'
import { Space, TableProps } from 'antd'
import { Button, ProfileButton } from '@/components/Button'
import Item from './Item'
import Switch from './Switch'
import { ProfileTable } from '@/components/Table'
import ColorfulText from '@/components/ColorfulText'
import { NFT as NFTType, UserAPI } from '@/services/userAPI'
import { errorNotice, notice } from '@/common/tip'
import { getLangData, getMediaSrc } from '@/utils/utils'
import { DefaultNFT } from '../image'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { useModal } from '@/store/useModal'
import TransferModal from './TransferModal'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB2_TYPE } from '@/constants/user'
import NFTItemSkeleton from './NFTItemSkeleton'
import Select from '@/components/Select'
import { useBaseConfig } from '@/store/useBaseConfig'

const PAGE_SIZE = 12
const TYPE = 1

const Web2NFT: React.FC = () => {
  const [isDetail, setIsDetail] = useState(true)
  const [nfts, setNFTs] = useState<NFTType[]>([])
  const history = useHistory()
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const { open } = useModal()
  const { info } = useUserInfo()
  const isWeb2 = info?.type === WEB2_TYPE

  const [chainCode, setChainCode] = useState<string>()
  // const {
  //   baseConfig: { supportChainInfos }
  // } = useBaseConfig()

  // const options = useMemo(() => {
  //   return (
  //     supportChainInfos?.map((chain) => ({
  //       label: chain.chainName,
  //       value: chain.chainCode
  //     })) ?? []
  //   )
  // }, [supportChainInfos])

  // useEffect(() => {
  //   if (!chainCode && supportChainInfos?.length) {
  //     setChainCode(supportChainInfos[0].chainCode)
  //   }
  // }, [supportChainInfos, chainCode])

  function toDetail(id: string, tokenId: string) {
    history.push(`/profile/nft-detail?id=${id}&tokenId=${tokenId}`)
  }

  function toWithdraw(nftId: string, tokenId: string) {
    history.push(`/profile/nft-withdraw?nftId=${nftId}&tokenId=${tokenId}`)
  }

  const columns: TableProps<NFTType>['columns'] = [
    {
      title: t('profile.icon'),
      dataIndex: 'showImageUrl',
      key: 'showImageUrl',
      align: 'center',
      width: 100,
      render(val, item) {
        const src = getMediaSrc(item.showImageUrl) || DefaultNFT
        return <img className={styles.avatar} src={src} />
      }
    },
    {
      title: t('profile.name'),
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 200,
      render(val, item) {
        return getLangData(item.goodsNameI18n)
      }
    },
    {
      title: t('profile.price'),
      dataIndex: 'exchangePrice',
      key: 'exchangePrice',
      render(val, item) {
        return (
          <ColorfulText
            text={`${item.exchangePrice} ${t('gifr.pick')}`}
            colors={['#9A6BFE', '#FC78FF']}
            inline
            className={styles.price}
          />
        )
      }
    },
    {
      title: t('profile.operate'),
      dataIndex: 'operate',
      key: 'operate',
      align: 'center',
      width: 100,
      render(val, item) {
        return (
          <Space size={14}>
            <ProfileButton onClick={() => toDetail(item.id, item.tokenId)}>
              {t('wallet.nft.detail')}
            </ProfileButton>
            {item.withdrawEnable === 1 && (
              <ProfileButton onClick={() => toWithdraw(item.id, item.tokenId)}>
                {t('wallet.nft.transfer')}
              </ProfileButton>
            )}
          </Space>
        )
      }
    }
  ]

  async function getNFT() {
    setIsLoading(true)
    if (chainCode === 'BSC') {
      // bsc现在是无数据的，直接清空数据
      setNFTs([])
      setTotal(0)
      setIsLoading(false)
      setPageNo(1)
      return
    }
    try {
      const res = await UserAPI.getNFTList({
        pageNo,
        pageSize: PAGE_SIZE,
        type: TYPE
      })
      if (res.data.code === 0) {
        setNFTs(res.data.data.records)
        setTotal(+res.data.data.total)
      } else {
        errorNotice(res.data.message)
      }
    } catch (error) {
      notice(String(error))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getNFT()
  }, [pageNo, chainCode])

  async function handleTransferIn() {
    open(<TransferModal />)
  }

  return (
    <Page navigator={NFT_MANAGE}>
      <TitleModule
        title={t('profile.myNFT')}
        more={false}
        className={styles.module}
      >
        <div className={styles.header}>
          <div className={styles.left}>
            {/* <Select
              options={options}
              value={chainCode}
              onChange={(val) => setChainCode(val as string)}
              className={styles.select}
            /> */}
            <Switch open={isDetail} onClick={() => setIsDetail((o) => !o)} />
          </div>
          {isWeb2 && (
            <Button onClick={handleTransferIn} className={styles.transferInBtn}>
              {t('wallet.nft.depositNFT')}
            </Button>
          )}
        </div>
        {isLoading && isDetail ? (
          <div className={styles.list}>
            {[1, 2, 3, 4].map((item) => (
              <NFTItemSkeleton key={item} />
            ))}
          </div>
        ) : (
          <Empty data={nfts}>
            {isDetail ? (
              <div className={styles.list}>
                {nfts.map((nft) => {
                  return (
                    <Item
                      key={`${nft.contractAddress}_${nft.tokenId}`}
                      data={nft}
                    />
                  )
                })}
              </div>
            ) : (
              <ProfileTable
                dataSource={nfts}
                columns={columns}
                pagination={false}
                rowKey={(record) =>
                  `${record.contractAddress}_${record.tokenId}`
                }
              />
            )}
            <div className={styles.paginationCtn}>
              <Pagination
                total={total}
                showSizeChanger={false}
                onChange={(page) => setPageNo(page)}
                current={pageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        )}
      </TitleModule>
    </Page>
  )
}

export default React.memo(Web2NFT)
