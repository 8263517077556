import { service } from '../http'
import {
  CreateDigitalOrderParams,
  CreateDigitalOrderResponse,
  GetAchPaymentUrlParams,
  GetAchPaymentUrlResponse,
  GetOrderResp,
  GetOrdersParams,
  NotifyHashParams
} from './model'
import { PromiseResponse } from '../common'

const BASE_URL = '/payment/api/v1'

export class PaymentApi {
  // 获取充值的第三方链接
  static async getAchPaymentUrl(
    params: GetAchPaymentUrlParams
  ): PromiseResponse<GetAchPaymentUrlResponse> {
    return await service.post(`${BASE_URL}/get_ach_payment_url`, params)
  }
  // 钻石充值
  static async createDigitalOrder(
    params: CreateDigitalOrderParams
  ): PromiseResponse<CreateDigitalOrderResponse> {
    return await service.post(`${BASE_URL}/create_digital_order`, params)
  }
  // web3钻石充值，通知hash
  static async notifyHash(params: NotifyHashParams): PromiseResponse<void> {
    return await service.post(`${BASE_URL}/notify_hash`, params)
  }
  // 获取订单列表
  static async getOrders(
    params: GetOrdersParams
  ): PromiseResponse<GetOrderResp> {
    return await service.post(`${BASE_URL}/get_orders`, params)
  }
}
