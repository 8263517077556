import React, { useState } from 'react'
import styles from './style.module.less'
import data from '@/data/profile/meetfqa.json'
import { Arrow } from '@/assets'
import classNames from 'classnames'
import { LangData } from '@/common/types'
import { getLangData } from '@/utils/utils'
import { useTranslation } from 'react-i18next'

type QAProps = {
  qa: {
    problem: LangData
    answer: LangData
  }
}

function QA({ qa }: QAProps) {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.qa}>
      <div className={styles.questionCtn} onClick={() => setOpen(!open)}>
        <div>{getLangData(qa.problem)}</div>
        <img
          src={Arrow}
          className={classNames(styles.arrow, open && styles.openedArrow)}
        />
      </div>
      {open && <div className={styles.answer}>{getLangData(qa.answer)}</div>}
    </div>
  )
}

const Help: React.FC = () => {
  useTranslation()
  return (
    <div className={styles.help}>
      {data.map((item: any, i) => {
        return <QA key={i} qa={item} />
      })}
    </div>
  )
}

export default React.memo(Help)
