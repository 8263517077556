import { useBalance, useChainId } from 'wagmi'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB3_TYPE } from '@/constants/user'
import { errorNotice } from '@/common/tip'
import { WalletAddress } from '@/common/types'
import { useMemo } from 'react'

export default function useBNB(decimal = 4) {
  const { info } = useUserInfo()
  const chainId = useChainId()

  const { data, refetch, isLoading } = useBalance({
    address: info?.address as WalletAddress,
    chainId,
    query: {
      enabled: info?.type === WEB3_TYPE && !!chainId
    }
  })

  const formatBalance = useMemo(() => {
    if (!data?.value) return 0
    const bnbNumber = Number(data.value) / 10 ** 18
    const factor = 10 ** decimal
    return Math.floor(bnbNumber * factor) / factor // 向下取整到小数点后4位
  }, [data?.value])

  function handleRefetch() {
    const start = +new Date()
    const timer = setInterval(async () => {
      try {
        if (start + 5000 > +new Date()) {
          await refetch()
          clearInterval(timer)
        } else {
          clearInterval(timer)
        }
      } catch (error: any) {
        errorNotice(error)
      }
    }, 1000)
  }

  return {
    data: formatBalance,
    refetch: handleRefetch,
    isLoading
  }
}
