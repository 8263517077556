import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import DefaultNFT from '@/assets/images/profile/default-nft.png'
import classNames from 'classnames'

type Props = {
  className?: string
}

const ImgSkeleton: React.FC<PropsWithChildren<Props>> = ({
  className = '',
  children
}) => {
  return (
    <div className={classNames(styles.imgCtn, className)}>
      <img className={styles.bg} />
      {!children && <img src={DefaultNFT} alt="NFT" className={styles.img} />}
      {children}
    </div>
  )
}

export default React.memo(ImgSkeleton)
