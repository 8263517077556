import React from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { ModalProps } from '@/components/Modal'
import { Button } from '@/components/Button'

type Props = {
  num: number
  onOk?: () => void
} & ModalProps

const RateChangeModal: React.FC<Props> = ({ num, onOk, onDismiss }) => {
  return (
    <ModalWrapper title="提示" onDismiss={onDismiss} width={450}>
      <div>{`由于汇率波动，当前获得的钻石数量${num}，是否继续？`}</div>
      <div className={styles.btns}>
        <Button onClick={onOk} className={styles.btn}>
          确定
        </Button>
        <Button onClick={onDismiss} borderOnly className={styles.btn}>
          取消
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default React.memo(RateChangeModal)
