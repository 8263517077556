import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import { useMemo } from 'react'

export default function useTokenList() {
  const { data: tokenListData } = useQuery(() => {
    return WalletApi.getCoinTokenList()
  })

  const tokenList = useMemo(() => {
    return tokenListData?.tokenList ?? []
  }, [tokenListData])

  const coinList = useMemo(() => {
    return tokenList.map((item) => ({
      code: item.coinCode,
      icon: item.coinImg
    }))
  }, [tokenList])

  return {
    tokenList,
    coinList
  }
}
