import styles from './style.module.less'
import {
  TwitterIcon,
  BilibiliIcon,
  DiscordIcon,
  MediumIcon,
  TiktokIcon,
  YoutubeIcon,
  QQIcon,
  QQGroupQrCode
} from './images'
import React from 'react'
import QrCodePopover from '../QrCodePopover'
import { formatTime, openWindow } from '@/utils/utils'
import { TA_EVENT_MAP, taTrack } from '@/common/thinkingdata'
import classNames from 'classnames'

const icons = [
  {
    src: TwitterIcon,
    title: 'twitter',
    href: 'https://twitter.com/meet_48'
  },
  {
    src: DiscordIcon,
    title: 'discord',
    href: 'https://discord.com/invite/W2vXnPqYBx'
  },
  {
    src: MediumIcon,
    title: 'medium',
    href: 'https://medium.com/@Meet48'
  },
  {
    src: YoutubeIcon,
    title: 'youtube',
    href: 'https://www.youtube.com/@meet_48'
  },
  {
    src: TiktokIcon,
    title: 'tiktok',
    href: 'https://www.tiktok.com/@meet48official'
  },
  {
    src: BilibiliIcon,
    title: 'bilibili',
    href: 'https://space.bilibili.com/3493089496795329'
  }
]

type Props = {
  className?: string
}

const Footer = ({ className }: Props) => {
  function linkTo(href: string) {
    openWindow(href)
    taTrack(TA_EVENT_MAP.enterSocialMedia)
  }
  return (
    <div className={classNames(styles.footer, className)}>
      <div className={styles.copyright}>
        © 2023 Meet48. ALL RIGHT RESERVED.
      </div>
      <div className={styles.icons}>
        <QrCodePopover src={QQGroupQrCode}>
          <img src={QQIcon} className={styles.icon} />
        </QrCodePopover>
        {icons.map((icon) => {
          return (
            <img
              key={icon.title}
              src={icon.src}
              className={styles.icon}
              title={icon.title}
              onClick={() => linkTo(icon.href)}
            />
          )
        })}
      </div>
      <div className={styles.gitMsg}>
        {`git latest message: "${process.env.gitMessage}"`}
        {`git latest short commit: ${process.env.shortCommit}`}
        {`update time: ${formatTime(+`0x${process.env.hash}`)}`}
      </div>
    </div>
  )
}

export default React.memo(Footer)
