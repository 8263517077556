import React, { useCallback, useMemo, useState } from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { ModalProps } from '@/components/Modal'
import { ChainProvider } from './context/ChainContext'
import ChainSelector from './ChainSelector'
import TextCopy from '@/components/TextCopy'
import { useTranslation } from 'react-i18next'
import { SupportChain, useBaseConfig } from '@/store/useBaseConfig'
import { useNFTDeposit } from './hooks/useDepositAddress'

const TransferModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { t, i18n } = useTranslation()
  const { baseConfig } = useBaseConfig()
  const chains = useMemo(
    () => baseConfig?.supportChainInfos || [],
    [baseConfig?.supportChainInfos]
  )

  const [selectedChainCode, setSelectedChainCode] = useState(
    chains[0]?.chainCode || ''
  )
  const { address, qrCode } = useNFTDeposit(selectedChainCode)

  const handleChainChange = useCallback((chain: SupportChain) => {
    setSelectedChainCode(chain.chainCode)
  }, [])

  const tip = useMemo(() => {
    if (i18n.language === 'en_US') {
      return `Please ensure the NFT you are depositing is issued on the ${selectedChainCode} chain. NFTs from other blockchains cannot be deposited. Failed deposits will result in the loss of NFT assets and cannot be recovered.`
    }
    return `請確認您轉入的NFT發行在${selectedChainCode}鏈，其他區塊鏈上發行的NFT無法轉入。轉入失敗NFT資產將丟失無法找回。`
  }, [i18n.language, selectedChainCode])

  return (
    <ModalWrapper
      title={t('wallet.nft.depositNFT')}
      onDismiss={onDismiss}
      width={471}
    >
      <ChainProvider initialChains={chains} onChainChange={handleChainChange}>
        <ChainSelector />
        <div className={styles.wrapper}>
          {qrCode && (
            <img src={qrCode} alt="Deposit QR Code" className={styles.qrCode} />
          )}
          <div>
            {selectedChainCode} {t('wallet.nft.depositAddress')}
          </div>
          <TextCopy text={address ?? ''}>{address ?? ''}</TextCopy>
        </div>
      </ChainProvider>
      <div className={styles.tip}>{tip}</div>
    </ModalWrapper>
  )
}

export default React.memo(TransferModal)
