import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { TickSvg, DownArrow } from '@/assets'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg'
import { useChainContext } from '../context/ChainContext'

// 链选择器组件
function ChainSelector() {
  const { state, dispatch } = useChainContext()
  const { chains, selectedChain } = state
  const [isOpen, setIsOpen] = useState(false)

  const handleChainSelect = React.useCallback((chainId: string) => {
    setIsOpen(false)
    dispatch({ type: 'SELECT_CHAIN', payload: chainId })
  }, [])

  const renderChainList = useMemo(() => {
    return chains.map((chain) => (
      <div
        key={chain.chainId}
        className={classNames(
          styles.chainItem,
          selectedChain?.chainId === chain.chainId && styles.active
        )}
        onClick={() => handleChainSelect(chain.chainId)}
      >
        <img
          src={chain.chainIcon}
          className={styles.icon}
          alt={chain.chainCode}
        />
        <span>{chain.chainName}</span>
        {selectedChain?.chainId === chain.chainId && (
          <ReactSVG src={TickSvg} className={styles.tick} />
        )}
      </div>
    ))
  }, [chains, selectedChain, handleChainSelect])

  if (!selectedChain) return null

  return (
    <div className={classNames(isOpen ? styles.switchOpen : styles.switch)}>
      <div className={styles.selectedChain} onClick={() => setIsOpen(!isOpen)}>
        <span>
          <img
            src={selectedChain.chainIcon}
            className={styles.icon}
            alt={selectedChain.chainCode}
          />
          <span>{selectedChain.chainName}</span>
        </span>
        <img src={DownArrow} alt="dropdown" className={styles.arrow} />
      </div>
      <div className={styles.chainList}>{renderChainList}</div>
    </div>
  )
}

export default React.memo(ChainSelector)
