import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ShareIcon } from '@/assets'
import { errorNotice } from '@/common/tip'
import styles from './style.module.less'
import { XIcon, DiscordIcon, FacebookIcon } from '@/assets'
import { useUserInfo } from '@/store/useUserInfo'
import classNames from 'classnames'

export interface SocialMedia {
  name: string
  icon: string
}

export const socialMediaOptions = [
  { name: 'Twitter', icon: XIcon },
  { name: 'Discord', icon: DiscordIcon },
  { name: 'Facebook', icon: FacebookIcon }
]

interface ShareProps {
  className?: string
  position?: 'bottom-left' | 'bottom-right'
}

const Share: React.FC<ShareProps> = ({ className, position }) => {
  const [isPanelVisible, setPanelVisible] = useState(false)
  const shareComponentRef = useRef<HTMLDivElement>(null)
  const { info } = useUserInfo()
  const inviteCode = info?.inviteCode || ''

  const shareUrl = inviteCode
    ? window.location.href.includes('?')
      ? `${window.location.href}&invite=${inviteCode}`
      : `${window.location.href}?invite=${inviteCode}`
    : window.location.href

  const shareContent = `来MEET48看看 ${shareUrl}`

  const togglePanel = () => {
    setPanelVisible(!isPanelVisible)
  }

  const handleShare = (platform: SocialMedia) => {
    const urlMap: { [key: string]: string } = {
      Twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareContent)}`,
      Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
      Discord: `https://discord.com/invite/W2vXnPqYBx`
    }

    const url = urlMap[platform.name]
    if (url) {
      togglePanel()
      window.open(url, '_blank')
    } else {
      errorNotice(`Not support ${platform.name} sharing`)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shareComponentRef.current &&
        !shareComponentRef.current.contains(event.target as Node)
      ) {
        setPanelVisible(false)
      }
    }

    if (isPanelVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPanelVisible])

  const positionClass = useMemo(() => {
    switch (position) {
      case 'bottom-left':
        return styles.bottomLeft
      case 'bottom-right':
        return styles.bottomRight
      default:
        return styles.bottomRight
    }
  }, [position])

  return (
    <div
      ref={shareComponentRef}
      className={`${styles.shareComponent} ${className}`}
    >
      <button onClick={togglePanel} className={styles.shareButton}>
        <img src={ShareIcon} alt="Share" className={styles.socialMediaIcon} />
      </button>
      {isPanelVisible && (
        <div className={classNames(styles.socialMediaPanel, positionClass)}>
          {socialMediaOptions.map((option) => (
            <div
              key={option.name}
              className={styles.socialMediaOption}
              onClick={() => handleShare(option)}
            >
              <img
                src={option.icon}
                alt={option.name}
                className={styles.socialMediaIcon}
              />
              <span>{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Share
