import React from 'react'
import { Skeleton, Space } from 'antd'
import styles from './style.module.less'
import Module from '@/components/Module'

const AssetsDetailSkeleton: React.FC = () => {
  return (
    <Module className={styles.assetsDetail}>
      <div className={styles.form}>
        <div>
          <Skeleton.Input active size="large" style={{ width: 200 }} />
          <Skeleton.Avatar
            active
            size="large"
            shape="circle"
            style={{ marginLeft: 16, marginRight: 16 }}
          />
          <Skeleton.Input active size="large" style={{ width: 150 }} />
        </div>
        <Space>
          <Skeleton.Input active size="large" style={{ width: 200 }} />
          <Skeleton.Button
            active
            size="large"
            style={{ width: 80, marginLeft: 16 }}
          />
        </Space>
      </div>
      <div className={styles.divider}></div>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <div key={item} className={styles.linesSkeleton}>
          <div className={styles.leftSkeleton}>
            <Skeleton.Avatar active size="large" shape="circle" />
            <div>
              <Skeleton.Input active size="default" style={{ width: 100 }} />
              <Skeleton.Input active size="default" style={{ width: 200 }} />
            </div>
          </div>
          <div className={styles.rightSkeleton}>
            <Skeleton.Input active size="default" style={{ width: 100 }} />
          </div>
        </div>
      ))}
    </Module>
  )
}

export default AssetsDetailSkeleton
