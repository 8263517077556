import { service } from '../http'
import { FeedbackParams, LatestVersionDownloadURLs } from './model'
import { PromiseResponse } from '../common'
import { EncryptData } from '../MediaAPI'

const BASE_URL = '/common/api/v1'

export class CommonApi {
  static async getLatestVersionDownloadUrl(): PromiseResponse<LatestVersionDownloadURLs> {
    return service.get(`${BASE_URL}/appVersion/getLatestVersionDownloadUrl`)
  }
  // 上传文件
  static async fileUpload(file: File): PromiseResponse<string> {
    const formData = new FormData()
    formData.append('file', file)
    return service.post(`${BASE_URL}/file/upload`, formData, {
      headers: {
        isFormData: '1'
      }
    })
  }
  // 批量上传文件
  static async fileUploadBatch(files: File[]): PromiseResponse<string[]> {
    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    return service.post(`${BASE_URL}/file/uploadBatch`, formData, {
      headers: {
        isFormData: '1'
      }
    })
  }
  static async getBaseConfig(): PromiseResponse<EncryptData> {
    return service.get(`${BASE_URL}/getBaseConfig`)
  }
  // 在线反馈
  static async feedback(params: FeedbackParams): PromiseResponse<void> {
    return service.post(`${BASE_URL}/feedback/feedback`, params)
  }
}
