import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { ORDER, DIAMOND_CONSUME_RECORD } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import { StyledRangePicker } from '@/components/DatePicker'
import { Form } from 'antd'
import { ProfileButton } from '@/components/Button'
import { errorNotice } from '@/common/tip'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import { getDayEnd, getDayStart } from '@/common/profile'
import { Order, PaymentApi } from '@/services/payment'
import Select from '@/components/Select'
import DiamondConsumeTable from '../components/DiamondConsumeTable'

const PAGE_SIZE = 10

const DiamondConsumeRecord: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<Order[]>([])

  function getParams() {
    const values = form.getFieldsValue()
    const { range } = values
    const startTime = getDayStart(range?.[0]?.valueOf())
    const endTime = getDayEnd(range?.[1]?.valueOf())
    return {
      pageNo,
      pageSize: PAGE_SIZE,
      startTime,
      endTime,
      type: 2
    }
  }

  async function getConsumeRecord() {
    const params = getParams()
    const res = await PaymentApi.getOrders(params)
    if (res.data.code === 0) {
      setDataSource(res.data.data.data)
      setTotal(res.data.data.total)
    } else {
      errorNotice(res.data.message)
    }
  }

  async function reset() {
    setPageNo(1)
    form.resetFields(['range'])
    getConsumeRecord()
  }

  useEffect(() => {
    getConsumeRecord()
  }, [pageNo])

  return (
    <Page navigator={`${ORDER}/${DIAMOND_CONSUME_RECORD}`}>
      <TitleModule
        title={t('wallet.diamondConsumptionRecord')}
        more={false}
        className={styles.module}
      >
        <Form form={form} onFinish={getConsumeRecord} onReset={reset}>
          <div className={styles.search}>
            {/* <div className={styles.formItem}>
              <Form.Item noStyle initialValue={3}>
                <Select
                  options={[
                    { label: 'MEET48 dapp', value: 3 },
                    { label: 'MEET48.AI', value: 4 }
                  ]}
                />
              </Form.Item>
            </div> */}
            <div className={styles.formItem}>
              <Form.Item noStyle name="range">
                <StyledRangePicker
                  placeholder={[t('profile.startTime'), t('profile.endTime')]}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
            </div>
            <div className={styles.btns}>
              <ProfileButton
                className={styles.btn}
                type="submit"
                onClick={() => setPageNo(1)}
              >
                {t('normal.search')}
              </ProfileButton>
              <ProfileButton className={styles.btn} type="reset">
                {t('normal.reset')}
              </ProfileButton>
            </div>
          </div>
        </Form>
        <div className={styles.tableCtn}>
          <Empty data={dataSource}>
            <DiamondConsumeTable dataSource={dataSource} />
            <div className={styles.paginationCtn}>
              <Pagination
                total={total}
                showSizeChanger={false}
                onChange={(page) => setPageNo(page)}
                current={pageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        </div>
      </TitleModule>
    </Page>
  )
}

export default React.memo(DiamondConsumeRecord)
