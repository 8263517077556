import { getSearchParams } from '@/utils/utils'
import React, { useEffect } from 'react'

const Personal: React.FC = () => {
  useEffect(() => {
    // 如果url里有参数，就传把信息传给父窗口
    const params = getSearchParams()
    if (params.oauth_token && params.oauth_verifier) {
      // twitter 信息绑定
      const { oauth_token, oauth_verifier } = params
      window.opener?.postMessage({ oauth_token, oauth_verifier }, '*')
    }
    if (params.code) {
      // discord 信息绑定
      const { code } = params
      window.opener?.postMessage({ code }, '*')
    }
  }, [])

  return null
}

export default React.memo(Personal)
