import React from 'react'
import styles from './style.module.less'
import { SETTING } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import InputBox from '@/components/InputBox'
import { ProfileButton } from '@/components/Button'
import { useHistory } from 'react-router-dom'
import { useUserInfo } from '@/store/useUserInfo'
import { useTranslation } from 'react-i18next'
import GoogleAuthenticator from './GoogleAuthenticator'
import { WEB3_TYPE } from '@/constants/user'

const { LabelInput } = InputBox

const Setting: React.FC = () => {
  const { info } = useUserInfo()
  const history = useHistory()
  const { t } = useTranslation()

  const isWeb3 = info?.type === WEB3_TYPE

  return (
    <Page navigator={SETTING}>
      <TitleModule
        title={t('profile.accountSecurity')}
        more={false}
        className={styles.module}
      >
        <div className={styles.form}>
          <LabelInput
            label={t('profile.email')}
            defaultValue={info?.email}
            disabled
            inputExtra={
              <ProfileButton
                className={styles.settingBtn}
                onClick={() => history.push('/profile/update-email')}
              >
                {t('profile.setting')}
              </ProfileButton>
            }
          />
          {!isWeb3 && (
            <>
              <LabelInput
                label={t('personal.updatePwd')}
                type="password"
                disabled
                inputExtra={
                  <ProfileButton
                    className={styles.settingBtn}
                    onClick={() => history.push('/profile/update-password')}
                  >
                    {t('profile.setting')}
                  </ProfileButton>
                }
              />
              <LabelInput
                label={t('wallet.verify.fundPassword')}
                type="password"
                disabled
                inputExtra={
                  <ProfileButton
                    className={styles.settingBtn}
                    onClick={() => history.push('/profile/set-funds-password')}
                  >
                    {t('profile.setting')}
                  </ProfileButton>
                }
              />
              <GoogleAuthenticator />
            </>
          )}
        </div>
        {/* <div className={styles.logoffCtn}>
          <div className={styles.logoff}>账户注销</div>
        </div> */}
      </TitleModule>
    </Page>
  )
}

export default React.memo(Setting)
