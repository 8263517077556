import { decryptData } from '@/common/dataConvert'
import { WEB3_TYPE } from '@/constants/user'
import useQuery from '@/hooks/useQuery'
import { useCoinBalance } from '@/hooks/web3/coin/useCoinBalance'
import { ListResponse } from '@/services/common'
import { CurrencyAsset, SupportToken, WalletApi } from '@/services/wallet'
import { useUserInfo } from '@/store/useUserInfo'
import { round } from '@/utils/utils'
import { useMemo } from 'react'

export default function useBalance(
  sendCoin: string,
  tokenList: SupportToken[]
) {
  const { info } = useUserInfo()
  const { usdt, bnb } = useCoinBalance(tokenList)
  const { data: originAssetList } = useQuery(
    () => {
      return WalletApi.getAssetList({
        pageSize: 20,
        pageNo: 1
      })
    },
    [],
    { manual: info?.type === WEB3_TYPE }
  )

  const listData = useMemo<ListResponse<{ records: CurrencyAsset[] }>>(() => {
    if (originAssetList) {
      return decryptData(originAssetList)
    }
  }, [originAssetList])

  const balance = useMemo(() => {
    if (info?.type === WEB3_TYPE) {
      return round(sendCoin === 'USDT' ? usdt : bnb, 4)
    }
    return Number(
      listData?.records.find((item) => item.code === sendCoin)?.available ?? 0
    )
  }, [listData, sendCoin, info?.type, usdt, bnb])

  return balance
}
