import React, { useState } from 'react'
import styles from './style.module.less'
import { Page, TitleModule } from '../components'
import { DAZZLE_MANAGE } from '@/constants/profile'
import type { TabsProps } from 'antd'
import Creations from './Creations'
import Tabs from '@/components/Tabs'
import { useTranslation } from 'react-i18next'
import Empty from '@/components/Empty'

const DazzleManage: React.FC = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('1')

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: t('wallet.aigcWorks'),
      children: (
        <div className={styles.aigc}>
          <Empty data={[]} />
        </div>
      )
    },
    {
      key: '2',
      label: t('wallet.uploadWorks'),
      children: <Creations />
    }
  ]

  return (
    <Page navigator={DAZZLE_MANAGE}>
      <TitleModule title={t('dazzle.myCreations')} more={false}>
        <Tabs items={tabs} activeKey={tab} onChange={(key) => setTab(key)} />
      </TitleModule>
    </Page>
  )
}

export default React.memo(DazzleManage)
