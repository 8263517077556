import React from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { ModalProps } from '@/components/Modal'
import { NFT } from '@/services/userAPI'
import { getLangData, getMediaSrc } from '@/utils/utils'
import { Button } from '@/components/Button'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Props = {
  data: NFT[]
} & ModalProps

const OpenBlindBoxSuccessModal: React.FC<Props> = ({ onDismiss, data }) => {
  const history = useHistory()
  const { t } = useTranslation()

  function toDetail(item: NFT) {
    history.push(`/profile/nft-detail?id=${item.id}&tokenId=${item.tokenId}`)
    onDismiss?.()
  }

  return (
    <ModalWrapper
      onDismiss={onDismiss}
      title={t('profile.CongratulationsOnGetting')}
      width={440}
    >
      <div className={styles.content}>
        {data.map((item) => {
          return (
            <div key={item.id} className={styles.item}>
              <img
                src={getMediaSrc(item.showImageUrl)}
                className={styles.img}
              />
              <div className={styles.name}>
                {getLangData(item.goodsNameI18n)}
              </div>
              <Button className={styles.btn} onClick={() => toDetail(item)}>
                {t('profile.details')}
              </Button>
            </div>
          )
        })}
      </div>
    </ModalWrapper>
  )
}

export default React.memo(OpenBlindBoxSuccessModal)
