import { toAuthorize } from '@/common/login'
import { errorNotice } from '@/common/tip'
import { WalletApi } from '@/services/wallet'
import { useUserInfo } from '@/store/useUserInfo'
import { getSearchParams } from '@/utils/utils'
import { create } from 'zustand'

async function ssoLogin(code: string) {
  const res = await WalletApi.ssoLogin(code)
  if (res.data.code === 0) {
    return res.data.data
  } else {
    errorNotice(res.data.message)
    return null
  }
}

type State = { isChecked?: boolean }

type Actions = {
  authorize: () => Promise<void>
}

const useSSOLogin = create<State & Actions>((set) => ({
  isChecked: false,
  authorize: async () => {
    if (location.href.includes('/personal')) {
      set({ isChecked: true })
      return
    }
    if (useUserInfo.getState().info) {
      set({ isChecked: true })
      return
    }
    const { code, noLogin } = getSearchParams()
    if (noLogin) {
      set({ isChecked: true })
      return
    }
    // 根据code获取用户信息
    try {
      if (code) {
        const info = await ssoLogin(code)
        if (info) {
          useUserInfo.getState().save(info)
        }
      } else {
        // 跳转到鉴权页面
        toAuthorize(location.href)
      }
    } finally {
      set({ isChecked: true })
    }
  }
}))

export { useSSOLogin }
