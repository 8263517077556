import { useState } from 'react'
import { useChainId, useSwitchChain } from 'wagmi'
import { BNBSvg, OPBNBSvg } from '@/assets'

type ChainOption = {
  id: number
  name: string
  svg: string
}

// 定义支持的链信息
export const SUPPORTED_CHAINS: ChainOption[] = process.env.isTestOrDev
  ? [
      { id: 5611, name: 'opBNB Testnet', svg: OPBNBSvg },
      { id: 97, name: 'BNB Smart Chain Testnet', svg: BNBSvg }
    ]
  : [
      { id: 204, name: 'opBNB Mainnet', svg: OPBNBSvg },
      { id: 56, name: 'BNB Smart Chain Mainnet', svg: BNBSvg }
    ]
/**
 * useChainSwitch Hook
 * @returns currentChain - 当前链信息
 * @returns switchCurrentChain - 切换链的方法
 * @returns isPending - 是否正在切换
 * @returns error - 切换错误信息
 */
export const useChainSwitch = () => {
  const chainId = useChainId() // 当前网络
  const { switchChainAsync, isPending, error } = useSwitchChain() // 切换网络
  const [currentChain, setCurrentChain] = useState<ChainOption | null>(
    SUPPORTED_CHAINS.find((c) => c.id === chainId) || null
  )

  const switchCurrentChain = async (targetChainId: number) => {
    const targetChain = SUPPORTED_CHAINS.find((c) => c.id === targetChainId)
    if (!targetChain) {
      console.error('Unsupported chain id:', targetChainId)
      return
    }

    await switchChainAsync({ chainId: targetChain.id })
    setCurrentChain(targetChain)
  }

  return {
    currentChain,
    switchCurrentChain,
    isPending,
    error
  }
}
