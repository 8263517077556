import { useReadContracts } from 'wagmi'
import { useChainId } from 'wagmi'
import { erc721Abi } from '@/abi/erc721'
import { erc1155Abi } from '@/abi/erc1155'
import { ExtendNFT, OwnedNFT, SupportNFT, WalletApi } from '@/services/wallet'
import { WalletAddress } from '@/common/types'
import { Abi } from 'viem'
import { useEffect, useState, useMemo } from 'react'
import { errorNotice } from '@/common/tip'
import { type UseReadContractsReturnType } from 'wagmi'
import { NFTExtendInfoListParams, UserAPI } from '@/services/userAPI'
import { useUserInfo } from '@/store/useUserInfo'

type ContractParams = {
  address: WalletAddress
  abi: Abi
  functionName: string
  args: (WalletAddress | bigint)[]
}

export function useFetchNFT() {
  const chainId = useChainId()
  const { info } = useUserInfo()
  // const testAddress = '0x6c3E8a17393669FA9Eb40D015577f465b90C3Ab9'
  // const testAddress = '0xB25B0288d870FE49e21DAbc8C5569452c0672c29'

  const [supportNFTList, setSupportNFTList] = useState<SupportNFT[]>([])
  const [extendData, setExtendData] = useState<ExtendNFT[]>([])
  const [isFullLoading, setIsFullLoading] = useState(true)

  useEffect(() => {
    const fetchNFTContracts = async () => {
      if (!chainId) return
      const response = await WalletApi.getNFTSupportList(chainId)
      if (response?.data?.data) {
        // 去重
        const uniContracts = Array.from(
          new Set(
            response?.data?.data.map(
              ({ contractAddress, tokenType, nftId }) =>
                `${contractAddress}_${tokenType}_${nftId}`
            )
          )
        ).map((key) => {
          const [contractAddress, tokenType, nftId] = key.split('_')
          return response?.data?.data.find(
            (item) =>
              item.contractAddress === contractAddress &&
              item.tokenType === Number(tokenType) &&
              item.nftId === Number(nftId)
          )
        }) as SupportNFT[]
        setSupportNFTList(uniContracts)
      }
    }
    fetchNFTContracts()
  }, [chainId])

  // 处理合约参数,分两种合约，配置合约参数
  const handleContractParams = (contracts: SupportNFT[]) => {
    const contractCalls = contracts.flatMap((contract): ContractParams[] => {
      const isERC721 = contract.tokenType === 3
      const isERC1155 = contract.tokenType === 4

      if (!isERC721 && !isERC1155) {
        console.warn(`Unsupported token type: ${contract.tokenType}`)
        return []
      }

      const balanceCall = {
        address: contract.contractAddress as WalletAddress,
        abi: isERC721 ? (erc721Abi as Abi) : (erc1155Abi as Abi),
        functionName: 'balanceOf',
        args: isERC721
          ? [info?.address as WalletAddress]
          : [info?.address as WalletAddress, BigInt(contract.nftId)]
      }
      return [balanceCall]
    })
    return contractCalls
  }

  // 验证合约，控制触发
  const validContracts = useMemo(() => {
    const contractParams = handleContractParams(supportNFTList) // 去重
    const shouldExecute = supportNFTList.length > 0 // 控制触发 && otherConditions
    return shouldExecute ? contractParams : []
  }, [supportNFTList]) // 可以添加其他依赖项

  // 3.获取合约数据
  const {
    data,
    isError,
    isLoading,
    refetch,
    isSuccess,
    error
  }: UseReadContractsReturnType = useReadContracts({
    contracts: validContracts
  })

  useEffect(() => {
    if (isError && error) {
      errorNotice(error)
    }
  }, [isError, error])

  const processNFTData = async (data: any, supportNFTList: SupportNFT[]) => {
    // 只有在有数据的情况下才进行处理
    if (
      !data ||
      data.length === 0 ||
      !supportNFTList ||
      supportNFTList.length === 0
    ) {
      setExtendData([])
      setIsFullLoading(false)
      return
    }

    setIsFullLoading(true)

    try {
      const ownedNFTs = filterNFTData(data, supportNFTList)

      // 如果没有拥有的NFT，直接设置空数组
      if (ownedNFTs.length === 0) {
        setExtendData([])
        return
      }

      const extendedData = await extendNFTData(ownedNFTs)
      const extendedRes = [] as ExtendNFT[]

      extendedData.forEach((item) => {
        const nft = ownedNFTs.find(
          (nft) => nft.nftId === item.nftId && nft.id === item.tokenId
        )
        if (nft) {
          extendedRes.push({
            ...nft,
            goodsNameI18n: item.goodsNameI18n,
            showImageUrl: item.showImageUrl,
            exchangePrice: item.exchangePrice,
            tokenId: item.tokenId.toString(),
            blockTime: nft.blockTime
          })
        } else {
          console.log('nft not found:', item)
        }
      })

      // 只有在有扩展数据的情况下才更新
      if (extendedRes.length > 0) {
        setExtendData(extendedRes)
      } else {
        setExtendData([])
      }
    } catch (error) {
      console.error('NFT Data Processing Error:', error)
      // 出错时也清空数据，防止显示过期或不完整的数据
      setExtendData([])
    } finally {
      setIsFullLoading(false)
    }
  }

  // 过滤余额大于0的NFT
  const filterNFTData = (data: any[], supportNFTList: SupportNFT[]) => {
    const ownedNFTs = []

    for (let i = 0; i < data.length; i += 1) {
      const balanceResult = data[i]
      const contract = supportNFTList[i]

      if (
        balanceResult?.status === 'success' &&
        balanceResult.result &&
        BigInt(balanceResult.result.toString()) > 0n
      ) {
        ownedNFTs.push({
          ...contract,
          balance: balanceResult.result.toString()
        })
      }
    }
    return ownedNFTs as OwnedNFT[]
  }

  const extendNFTData = async (nftList: OwnedNFT[]) => {
    const nftParams: NFTExtendInfoListParams[] = nftList.map((nft) => {
      return {
        tokenId: nft.id,
        nftId: nft.nftId
      }
    })
    const response = await UserAPI.getNFTExtendInfo(nftParams)
    return response?.data.data || []
  }

  useEffect(() => {
    if (isSuccess && data) {
      processNFTData(data, supportNFTList)
    } else {
      setIsFullLoading(false)
      setExtendData([])
    }
  }, [isSuccess, isError, data, supportNFTList])

  return {
    extendData,
    isError,
    isLoading: isLoading || isFullLoading,
    isSuccess,
    refetch
  }
}
