import React from 'react'
import { Skeleton } from 'antd'
import styles from '../Total/style.module.less'

const TotalSkeleton: React.FC = () => {
  return (
    <div className={styles.info}>
      <div className={styles.titleCtn}>
        <span className={styles.title}>
          <Skeleton.Button
            active
            size="default"
            style={{ width: 200, height: 20 }}
          />
        </span>
      </div>
      <div className={styles.currency}>
        <span className={styles.balance}>
          <Skeleton.Button
            active
            size="large"
            style={{ width: 80, height: 36 }}
          />
        </span>
      </div>
      <div className={styles.dollar}>
        <Skeleton.Button
          active
          size="small"
          style={{ width: 80, height: 20 }}
        />
      </div>
    </div>
  )
}

export default TotalSkeleton
