import React from 'react'
import { Page } from '../components'
import { ASSETS } from '@/constants/profile'
import Web2Assets from './Web2Assets'
import Web3Assets from './Web3Assets'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB2_TYPE } from '@/constants/user'

const Assets: React.FC = () => {
  const { info } = useUserInfo()
  const isWeb2 = info?.type === WEB2_TYPE
  return (
    <Page navigator={ASSETS}>{isWeb2 ? <Web2Assets /> : <Web3Assets />}</Page>
  )
}

export default React.memo(Assets)
