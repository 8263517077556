import { useMemo } from 'react'
import { useReadContract } from 'wagmi'
import { TokenType, WalletAddress } from '@/common/types'
import { Abi } from 'viem'
import { erc721Abi } from '@/abi/erc721'
import { erc1155Abi } from '@/abi/erc1155'

export type NFTBalanceParams = {
  nftId: number
  contractAddress?: WalletAddress
  address?: WalletAddress
  tokenType?: TokenType
}

export function useNFTBalance({
  nftId,
  contractAddress,
  address,
  tokenType
}: NFTBalanceParams) {
  // const testAddress = '0xB25B0288d870FE49e21DAbc8C5569452c0672c29'

  const abis = {
    [3 as TokenType]: erc721Abi,
    [4 as TokenType]: erc1155Abi
  }

  const args = useMemo(() => {
    return {
      [3 as TokenType]: [address as WalletAddress],
      [4 as TokenType]: [address as WalletAddress, BigInt(nftId)]
    }
  }, [address, nftId])

  const contractParams = useMemo(() => {
    return {
      address: contractAddress as WalletAddress,
      abi: typeof tokenType === 'number' ? (abis[tokenType] as Abi) : undefined,
      functionName: 'balanceOf',
      args: args[tokenType as TokenType],
      query: {
        enabled: !!address && !!contractAddress
      }
    }
  }, [contractAddress, tokenType, address, args])

  const { data, isError, isLoading, isSuccess, error } =
    useReadContract(contractParams)

  return {
    data: data as { result: bigint; status: string },
    isError,
    isLoading,
    isSuccess
  }
}
