import axios from 'axios'
import ERROR_STATUS_MAP from './errorStatus'
import { useUserInfo } from 'store/useUserInfo'
import i18next from 'i18next'
import { notify } from '@/common/tip'
import { taLogout } from '@/common/thinkingdata'
import { debouncePromise } from '@/utils/utils'
import { processConfig } from './processConfig'

// 默认请求超时时间
const timeout = 60000

// 创建axios实例
export const service = axios.create({
  timeout,
  baseURL: '',
  //如需要携带cookie 该值需设为true
  withCredentials: true
})

const handleLogout = debouncePromise(async () => {
  taLogout()
  useUserInfo.getState().clear()
  // notify(i18next.t('login.loginExpired'), i18next.t('login.loginExpiredTip'))
  location.hash = '#/?noLogin=1'
  location.reload()
}, 100)

const errorHandle = (error: any) => {
  if (error.response?.status === 401) {
    handleLogout()
    return
  }
  let message = `${error.response?.status ?? ''} ${error.code}`
  let description = `${i18next.t('http.serverError')} \n ${error.config?.url}`
  if (/timeout/i.test(error.message)) {
    message = error.code
    description = `${i18next.t('http.timeout')} \n ${error.config?.url}`
  }
  if (ERROR_STATUS_MAP.has(error.response?.status)) {
    const errorDesc = ERROR_STATUS_MAP.get(error.response.status)!
    message = `${error.response.status} ${errorDesc.title}`
    description = `${i18next.t(errorDesc.detail)} \n ${error.config.url}`
  }
  notify(message, description)
}

//统一请求拦截 可配置自定义headers 例如 language、token等
service.interceptors.request.use((config) => {
  processConfig(config)
  return config
})

service.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    errorHandle(error)
    return { data: { message: '' } } as any
  }
)
