import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import {
  CurrencySelect,
  Description,
  GoBackTitle,
  ExistAddressSelect
} from '../../components'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import Tabs from '@/components/Tabs'
import type { TabsProps } from 'antd'
import Select from '@/components/Select'
import { Button } from '@/components/Button'
import InputBox from '@/components/InputBox'
import { Form as AntdForm } from 'antd'
import useQuery from '@/hooks/useQuery'
import {
  AssetDetail,
  SupportCoin,
  WalletApi,
  WithdrawSafeVerificationParams
} from '@/services/wallet'
import { useSafeVerification } from '@/store/useSafeVerification'
import { errorNotice, notice } from '@/common/tip'
import {
  canConvertToNumber,
  getNumberPrecision,
  getSearchParams
} from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import NetworkSelect from '../../components/NetworkSelect'
import { checkWithdrawConditions } from '@/common/verify'

const { LabelInput } = InputBox

const DEFAULT_ASSET: AssetDetail = {
  withdrawAble: 0,
  available: 0
}

function getRangeText(range: [number, number]) {
  return range[0] === range[1] ? range[0] : `${range[0]}~${range[1]}`
}

const Form: React.FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [amount, setAmount] = useState('')
  const [withdrawType, setWithdrawType] = useState('1')
  const [form] = AntdForm.useForm()
  const [assetDetail, setAssetDetail] = useState(DEFAULT_ASSET)
  const [chainCode, setChainCode] = useState('')

  const { setCallback } = useSafeVerification()

  const [currency, setCurrency] = useState<SupportCoin>()
  const tokenList = currency?.tokenList ?? []
  // const { data: projectList = [] } = useQuery(WalletApi.getProjectList)
  const { coinCode: searchCoinCode } = getSearchParams()

  const { data: supportList = [] } = useQuery(() =>
    WalletApi.getCoinSupportList('Withdraw')
  )

  const token = useMemo(() => {
    return tokenList.find((token) => token.chainCode === chainCode)
  }, [tokenList, chainCode])

  async function handleSelect(c: SupportCoin) {
    setCurrency(c)
    const res = await WalletApi.getAssetDetail(c.coinCode)
    if (res.data.code === 0) {
      setAssetDetail(res.data.data)
    } else {
      errorNotice(res.data.message)
      setAssetDetail(DEFAULT_ASSET)
    }
  }

  const withdrawRange = useMemo<[number, number]>(() => {
    if (!currency) {
      return [0, 0]
    }
    if (chainCode) {
      const token = currency.tokenList.find(
        (token) => token.chainCode === chainCode
      )!
      return [
        +(token?.singleTimeMinWithdrawAmount ?? 0),
        +(token?.singleTimeMinWithdrawAmount ?? 0)
      ]
    }
    const amountList = currency.tokenList.map(
      (token) => +token.singleTimeMinWithdrawAmount
    )
    return [Math.min(...amountList), Math.max(...amountList)]
  }, [supportList.length, currency, chainCode])

  const feeRange = useMemo<[number, number]>(() => {
    if (!currency) {
      return [0, 0]
    }
    if (chainCode) {
      const token = currency.tokenList.find(
        (token) => token.chainCode === chainCode
      )!
      return [+token.withdrawFee, +token.withdrawFee]
    }
    const amountList = currency.tokenList.map((token) => +token.withdrawFee)
    return [Math.min(...amountList), Math.max(...amountList)]
  }, [supportList.length, currency, chainCode])

  const formTabs: TabsProps['items'] = [
    {
      key: '1',
      label: t('normal.address'),
      children: (
        <div className={styles.addressForm}>
          <AntdForm.Item noStyle name="toAddress">
            <ExistAddressSelect />
          </AntdForm.Item>
          <AntdForm.Item noStyle name="chainCode">
            <NetworkSelect
              label={t('wallet.assets.withdrawalNetwork')}
              placeholder={t('wallet.assets.withdrawalNetwork')}
              networks={tokenList}
              value={token?.chainId}
              onChange={(v) => {
                setChainCode(tokenList.find((n) => n.chainId === v)!.chainCode)
              }}
            />
          </AntdForm.Item>
          <AntdForm.Item noStyle name="amount">
            <LabelInput
              label={t('wallet.assets.withdrawalAmount')}
              placeholder={t('wallet.assets.withdrawalAmountPlaceholder')}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </AntdForm.Item>
          <div className={styles.info}>
            <Description
              label={t('wallet.assets.totalBalance')}
              desc={`${assetDetail.available} ${currency?.coinCode ?? ''}`}
            />
            <Description
              label={t('wallet.assets.minimumWithdrawal')}
              desc={`${getRangeText(withdrawRange)} ${currency?.coinCode ?? ''}`}
            />
            <Description
              label={t('wallet.assets.networkFee')}
              desc={`${getRangeText(feeRange)} ${currency?.coinCode ?? ''}`}
            />
            <Description
              label={t('wallet.assets.dayLimit')}
              desc={`${assetDetail.withdrawAble} ${currency?.coinCode ?? ''} / ${currency?.dayMaxWithDrawAmount ?? '0'} ${currency?.coinCode ?? ''}`}
            />
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: t('wallet.assets.internal'),
      children: (
        <div className={styles.addressForm}>
          <AntdForm.Item noStyle name="toUserCode">
            <LabelInput label="" placeholder={t('wallet.assets.recipientId')} />
          </AntdForm.Item>
          {/* <AntdForm.Item noStyle name="toUPid">
            <Select
              options={projectList.map((project) => ({
                label: project.projectName,
                value: project.id
              }))}
              placeholder={t('wallet.assets.withdrawProduct')}
            />
          </AntdForm.Item> */}
          <AntdForm.Item noStyle name="amount">
            <LabelInput
              label={t('wallet.assets.withdrawalAmount')}
              placeholder={t('wallet.assets.withdrawalAmountPlaceholder')}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </AntdForm.Item>
          <div className={styles.info}>
            <Description
              label={t('wallet.assets.totalBalance')}
              desc={`${assetDetail.available} ${currency?.coinCode ?? ''}`}
            />
            <Description
              label={t('wallet.assets.minimumWithdrawal')}
              desc={`${currency?.minWithDrawAmount ?? '0'} ${currency?.coinCode ?? ''}`}
            />
            <Description
              label={t('wallet.assets.dayLimit')}
              desc={`${assetDetail.withdrawAble} ${currency?.coinCode ?? ''} / ${currency?.dayMaxWithDrawAmount ?? '0'} ${currency?.coinCode ?? ''}`}
            />
          </div>
        </div>
      )
    }
  ]

  function getPrecisionTip(precision: number) {
    return i18n.language === 'en_US'
      ? `The decimal places should not exceed ${precision} digits.`
      : `小數點後不得超過${precision}位`
  }

  function verifyForm() {
    const values = form.getFieldsValue()
    if (!currency) {
      notice(t('wallet.form.selectCoinTip'))
      return false
    }
    if (!values.amount) {
      notice(t('wallet.form.amountTip'))
      return false
    }
    if (!canConvertToNumber(values.amount)) {
      notice(t('wallet.form.amountFormatTip'))
      return false
    }
    const amount = +values.amount
    if (amount > assetDetail.withdrawAble) {
      notice(t('wallet.form.withdrawalAmountOverDayLimit'))
      return false
    }
    const precision = getNumberPrecision(amount)
    if (precision > currency.precisionInApp) {
      notice(getPrecisionTip(currency.precisionInApp))
      return false
    }
    if (withdrawType === '1') {
      if (amount < withdrawRange[1]) {
        notice(t('wallet.form.withdrawalAmountLessMiniAmount'))
        return false
      }
      // 地址提现
      if (!values.toAddress) {
        notice(t('wallet.form.selectWithdrawalAddressTip'))
        return false
      }
      if (!/^(0x)?[0-9a-fA-F]{40}$/.test(values.toAddress)) {
        notice(t('shop.incorrectAddress'))
        return false
      }
      if (!chainCode) {
        notice(t('wallet.form.selectNetworkTip'))
        return false
      }
      if (amount + feeRange[1] > assetDetail.available) {
        notice(t('normal.insufficientBalance'))
        return false
      }
    } else {
      // if (!values.toUPid) {
      //   notice(t('wallet.form.projectSelectTip'))
      //   return false
      // }
      if (!values.toUserCode) {
        notice(t('wallet.form.recipientIdTip'))
        return false
      }
      if (amount > assetDetail.available) {
        notice(t('normal.insufficientBalance'))
        return false
      }
      if (amount < +currency.minWithDrawAmount) {
        notice(t('wallet.form.withdrawalAmountLessMiniAmount'))
        return false
      }
    }
    return true
  }

  async function checkAddressType() {
    const { toAddress } = form.getFieldsValue()
    const res = await WalletApi.getAddressType(toAddress)
    if (res.data.code === 0) {
      return res.data.data.addressType === 0
    } else {
      errorNotice(res.data.message)
      return false
    }
  }

  async function withdraw() {
    const isHostingAddress = await checkAddressType()
    if (!isHostingAddress) {
      notice(t('wallet.form.unableTransferToSelf'))
      return
    }
    const { amount, toAddress } = form.getFieldsValue()
    const tokenId = tokenList.find((item) => item.chainCode === chainCode)!.id
    const formData = {
      tokenId,
      withdrawalAmount: +amount,
      coinCode: currency!.coinCode,
      toAddress,
      chainCode
    }
    setCallback(async function (params: WithdrawSafeVerificationParams) {
      const res = await WalletApi.withdraw({ ...params, ...formData })
      if (res.data.code === 0) {
        notice(t('normal.submitSuccess'), 'success')
        history.push('/profile/assets')
      } else {
        errorNotice(res.data.message)
      }
    })
    history.push(
      `/profile/withdraw-safety-verification?amount=${amount}&coinId=${currency!.id}`
    )
  }

  async function internalTransfer() {
    const { amount, toUserCode } = form.getFieldsValue()
    const formData = {
      withdrawalAmount: +amount,
      coinCode: currency!.coinCode,
      toUserCode
    }
    setCallback(async function (params: WithdrawSafeVerificationParams) {
      const res = await WalletApi.internalTransfer({ ...params, ...formData })
      if (res.data.code === 0) {
        notice(t('normal.submitSuccess'), 'success')
        history.push('/profile/assets')
      } else {
        errorNotice(res.data.message)
      }
    })
    history.push(
      `/profile/withdraw-safety-verification?amount=${amount}&coinId=${currency!.id}`
    )
  }

  async function checkAddressValid() {
    const { toAddress } = form.getFieldsValue()
    const chainId = tokenList.find(
      (item) => item.chainCode === chainCode
    )!.chainId
    const res = await WalletApi.isAddressValid({ address: toAddress, chainId })
    if (res.data.code === 0) {
      return true
    } else {
      errorNotice(res.data.message)
      return false
    }
  }

  async function checkValid() {
    const { toUserCode } = form.getFieldsValue()
    const res = await WalletApi.isValid({ toUserCode })
    if (res.data.code === 0) {
      return true
    } else {
      errorNotice(res.data.message)
      return false
    }
  }

  async function submit() {
    const bound = await checkWithdrawConditions()
    if (!bound) {
      return
    }
    if (!verifyForm()) {
      return
    }
    if (withdrawType === '1') {
      const isValid = await checkAddressValid()
      if (!isValid) {
        return
      }
      withdraw()
    } else {
      const isValid = await checkValid()
      if (!isValid) {
        return
      }
      internalTransfer()
    }
  }

  return (
    <Module>
      <GoBackTitle
        title={t('wallet.assets.withdraw')}
        onClick={history.goBack}
      />
      <AntdForm form={form}>
        <div className={styles.form}>
          <AntdForm.Item noStyle name="coinId">
            <CurrencySelect
              label={t('wallet.currency')}
              onChange={(c) => handleSelect(c)}
              value={currency}
              type="Withdraw"
              coinCode={searchCoinCode}
            />
          </AntdForm.Item>
          <Tabs
            items={formTabs}
            className={styles.tabs}
            activeKey={withdrawType}
            onChange={(key) => setWithdrawType(key)}
          />
          <Button className={styles.btn} onClick={submit}>
            {t('wallet.assets.nextStep')}
          </Button>
        </div>
      </AntdForm>
    </Module>
  )
}

export default React.memo(Form)
