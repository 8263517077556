import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAccount, useDisconnect } from 'wagmi'
import { formatAddress, getEllipsisText, getMediaSrc } from '@/utils/utils'
import { useUserInfo } from 'store/useUserInfo'
import { useAssets } from 'store/useAssets'
import { UserInfo } from '@/services/wallet'
import styles from './style.module.less'
import { DownArrow } from '@/assets'
import { taEnterPageTrack, taLogout } from '@/common/thinkingdata'
import { WEB2_TYPE, WEB3_TYPE } from '@/constants/user'
import { toLogout } from '@/common/login'

interface AccountProp {
  userInfo: UserInfo
}

const AccountButton: React.FC<AccountProp> = ({ userInfo }) => {
  const { t } = useTranslation()

  const { clearAssets } = useAssets()

  const { isConnected } = useAccount()

  const history = useHistory()
  const { clear: clearUserInfo } = useUserInfo()

  const { pathname } = useLocation()
  const { disconnectAsync } = useDisconnect()

  const account = useMemo(() => {
    if (userInfo.userName) {
      return getEllipsisText(userInfo.userName, 6)
    }
    const addr = userInfo.address
    return formatAddress(addr!, 6, -4)
  }, [userInfo])

  //退出登录
  const handleLoginOut = async () => {
    if (userInfo.type === WEB3_TYPE && isConnected) {
      await disconnectAsync()
    }
    clearUserInfo() // 退出登录清除用户信息
    clearAssets()
    taLogout()
    const inProfile = pathname.startsWith('/profile')
    toLogout(
      inProfile ? `${location.origin}/#/` : `${location.origin}/#${pathname}`
    )
  }

  function to(path: string) {
    history.push(path)
    taEnterPageTrack(path, '导航栏')
  }

  return (
    <div className={styles.ctn}>
      <img
        src={getMediaSrc(userInfo.avatar)}
        className={styles.avatar}
        alt=""
      />
      <div className={styles.account}>{account}</div>
      <img className={styles.arrow} src={DownArrow} alt="down-arrow" />
      <div className={styles.dropdownCtn}>
        <div className={styles.dropdown}>
          <div onClick={() => to('/profile/base-info')} className={styles.item}>
            {t('personal.personal')}
          </div>
          <div onClick={() => to('/profile/assets')} className={styles.item}>
            {t('normal.asset')}
          </div>
          <div
            onClick={() => to('/profile/video-manage')}
            className={styles.item}
          >
            {t('dazzle.myCreations')}
          </div>
          <div onClick={() => to('/profile/nft')} className={styles.item}>
            {t('profile.myNFT')}
          </div>
          <div onClick={() => to('/profile/items')} className={styles.item}>
            {t('profile.myItems')}
          </div>
          <div
            onClick={() =>
              to(
                userInfo.type === WEB2_TYPE
                  ? '/profile/currency-record'
                  : '/profile/diamond-recharge-record'
              )
            }
            className={styles.item}
          >
            {t('profile.orderRecord')}
          </div>
          <div
            onClick={() => to('/profile/help-and-feedback')}
            className={styles.item}
          >
            {t('help.helpAndReport')}
          </div>
          <div onClick={() => to('/profile/setting')} className={styles.item}>
            {t('profile.setting')}
          </div>
          <div onClick={handleLoginOut} className={styles.signOut}>
            {t('login.logOut')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountButton
