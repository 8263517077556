import { useUserInfo } from '@/store/useUserInfo'
import { useTranslation } from 'react-i18next'
import { getAccount } from 'wagmi/actions'
import { config } from '@/config/wagmi'
import useWalletConnect from './useWalletConnect'

export default function useCheckAccount() {
  const { info } = useUserInfo()
  const { t } = useTranslation()
  const { onConnect } = useWalletConnect()

  async function checkAccount() {
    const success = await onConnect()
    if (!success) {
      return
    }
    // 使用同步方法获取 address，使用hooks的话当前事件循环address的值为undefined
    const { address } = getAccount(config)
    console.log('connected address:', address)
    if (address?.toLowerCase() !== info?.address.toLowerCase()) {
      throw new Error(t('normal.accountNotMatch'))
    }
  }

  return checkAccount
}
