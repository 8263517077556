import React, { useMemo, useState } from 'react'
import Total from './Total'
import AssetsDetail from './AssetsDetail'
import { useCoinBalance } from '@/hooks/web3/coin/useCoinBalance'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import useDiamondPoints from '@/hooks/web3/token/useDiamondPoints'
import Web3AssetsSkeleton from './Web3AssetsSkeleton'

const Web3Assets: React.FC = () => {
  const [showAsset, setShowAsset] = useState(true)
  function toggle() {
    setShowAsset((s) => !s)
  }

  const { data: tokenList = [] } = useQuery(WalletApi.getTokenSupportList)
  const { usdt, bnb, isLoading: isLoadingCoin } = useCoinBalance(tokenList)
  const {
    diamondAmount,
    diamondUSDValue,
    pointsAmount,
    pointsUSDValue,
    loading: isLoadingDiamond
  } = useDiamondPoints()

  const bnbUSD = tokenList.find((item) => item.coinCode === 'BNB')?.usd || 0

  const totalUsd = useMemo(() => {
    const total = usdt + bnb * Number(bnbUSD) + diamondUSDValue + pointsUSDValue
    return total.toFixed(4)
  }, [usdt, bnb, diamondUSDValue, pointsUSDValue])

  if (isLoadingCoin || isLoadingDiamond) return <Web3AssetsSkeleton />

  return (
    <>
      <Total showAsset={showAsset} toggle={toggle} totalUsd={totalUsd} />
      <AssetsDetail
        showAsset={showAsset}
        assets={{
          usdt,
          bnb,
          diamond: Number(diamondAmount),
          points: Number(pointsAmount),
          diamondUsdValue: diamondUSDValue,
          pointsUsdValue: pointsUSDValue
        }}
        tokenList={tokenList}
      />
    </>
  )
}

export default React.memo(Web3Assets)
