import { toLogin, toRegister } from '@/common/login'
import { useLocation } from 'react-router-dom'

export default function useToLogin() {
  const { pathname } = useLocation()

  function onLogin() {
    toLogin(`${location.origin}/#${pathname}`)
  }

  function onRegister() {
    toRegister(`${location.origin}/#${pathname}`)
  }

  return {
    onLogin,
    onRegister
  }
}
