import React from 'react'
import Page from './components/Page'
import BaseInfo from './BaseInfo'
import { PERSONAL } from '@/constants/profile'

const Profile: React.FC = () => {
  return (
    <Page navigator={PERSONAL}>
      <BaseInfo />
    </Page>
  )
}

export default React.memo(Profile)
