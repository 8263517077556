import React, { useEffect, useRef } from 'react'
import styles from './style.module.less'
import Media from '../Media'
import { useMediaInfo } from '@/store/useMediaInfo'
import { Twitter, Discord } from '@/views/Profile/image'
import { useUserInfo } from '@/store/useUserInfo'
import { errorNotice } from '@/common/tip'
import { TaskApi } from '@/services/task'
import { Fn } from '@/common/types'

const openAuthorizePage = (link: string) => {
  const width = 800
  const height = 600
  const left = (window.innerWidth - width) / 2
  const top = (window.innerHeight - height) / 2

  const options = `width=${width},height=${height},left=${left},top=${top}`

  return window.open(link, '_blank', options)
}

const Medias: React.FC = () => {
  const { mediaInfo, fetchMediaInfo } = useMediaInfo()
  const { info } = useUserInfo()
  const binding = useRef(false)
  const childWindow = useRef<any>(null)

  useEffect(() => {
    if (!info?.accountId) {
      return
    }
    const { accountId } = info
    async function listenHandle(event: MessageEvent<any>) {
      if (binding.current) {
        return
      }
      if (!event.data.code && !event.data.oauth_token) {
        return
      }
      childWindow.current?.close()
      binding.current = true
      let res: any = null

      if (event.data.code) {
        res = await TaskApi.getDiscordTokenAndBind({
          accountId,
          code: event.data.code
        })
      }
      if (event.data.oauth_token) {
        res = await TaskApi.getTwitterTokenAndBind({
          oauth_token: event.data.oauth_token,
          oauth_verifier: event.data.oauth_verifier,
          accountId
        })
      }
      binding.current = false
      if (res.data.code === 0) {
        fetchMediaInfo(accountId)
      } else {
        errorNotice(res.data.message)
      }
    }
    window.addEventListener('message', listenHandle)
    return () => {
      window.removeEventListener('message', listenHandle)
    }
  }, [info?.accountId])

  async function handleBind(request: Fn) {
    const {
      data: { code, data: authorizePath, message }
    } = await request()
    if (code === 0) {
      childWindow.current = openAuthorizePage(authorizePath)
    } else {
      errorNotice(message)
    }
  }

  function handleClick(title: string) {
    const request =
      title === 'Twitter' ? TaskApi.requestTokenLink : TaskApi.requestLoginLink
    handleBind(request)
  }

  return (
    <div className={styles.medias}>
      <Media
        icon={Twitter}
        title="Twitter"
        bound={!!mediaInfo.twitterToken}
        onClick={handleClick}
      />
      <Media
        icon={Discord}
        title="Discord"
        bound={!!mediaInfo.discordToken}
        onClick={handleClick}
      />
      {/* <Media icon={Telegram} title="Telegram" /> */}
    </div>
  )
}

export default React.memo(Medias)
