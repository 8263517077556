import React, { useState } from 'react'
import styles from './style.module.less'
import { ProfileTable } from '@/components/Table'
import { DepositRecord } from '@/services/wallet'
import { TableProps } from 'antd'
import { formatTime } from '@/utils/utils'
import { getTableCopyText } from '@/common/profile'
import { useTranslation } from 'react-i18next'
import { DownArrow } from '@/assets'
import classNames from 'classnames'

type Props = {
  dataSource: DepositRecord[]
  hideOrderId?: boolean
}

const statusMap: Record<string, string> = {
  1: 'wallet.success',
  2: 'wallet.underReview',
  3: 'wallet.pending',
  4: 'wallet.rejected'
}

const RechargeTable: React.FC<Props> = ({
  dataSource,
  hideOrderId = false
}) => {
  const { t } = useTranslation()

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  function onExpand(id: string) {
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys((keys) => keys.filter((key) => key !== id))
    } else {
      setExpandedRowKeys((keys) => [...keys, id])
    }
  }

  const columns: TableProps<DepositRecord>['columns'] = [
    {
      title: t('normal.amount') as string,
      dataIndex: 'amount',
      key: 'amount',
      align: 'left',
      render(val, record) {
        return (
          <div className={styles.amount}>
            <img src={record.coinImg} className={styles.currencyIcon} />
            <span className={styles.kind}>
              {val} {record.coinCode}
            </span>
          </div>
        )
      }
    },
    {
      title: t('normal.status') as string,
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      align: 'center',
      render(val, record) {
        const text = statusMap[record.orderStatus]
        return text ? t(text) : '--'
      }
    },
    {
      title: t('normal.time') as string,
      dataIndex: 'orderCreateTime',
      key: 'orderCreateTime',
      align: 'center',
      render(val, record) {
        return formatTime(+record.orderCreateTime)
      }
    },
    {
      title: t('normal.network') as string,
      dataIndex: 'chainCode',
      key: 'chainCode',
      align: 'center'
    },
    {
      title: t('wallet.from') as string,
      dataIndex: 'fromAddress',
      key: 'fromAddress',
      align: 'center',
      render(val, record) {
        return (
          <div className={styles.from}>
            <span>
              {record.fromAddress
                ? getTableCopyText(
                    record.fromAddress,
                    true,
                    record.fromAddressUrl
                  )
                : getTableCopyText(record.otherSideUserCode, false)}
            </span>
            <img
              src={DownArrow}
              className={classNames(
                styles.arrow,
                expandedRowKeys.includes(record.txId) && styles.expandedArrow
              )}
              onClick={() => onExpand(record.txId)}
            />
          </div>
        )
      }
    }
  ]

  const c = hideOrderId
    ? columns.filter((column) => column.key !== 'txId')
    : columns

  return (
    <div className={styles.tableCtn}>
      <ProfileTable
        rowKey={'txId'}
        dataSource={dataSource}
        columns={c}
        pagination={false}
        expandedRowKeys={expandedRowKeys}
        expandable={{
          expandIcon() {
            return null
          },
          expandedRowRender(record) {
            return (
              <div className={styles.extraRow}>
                <div className={styles.item}>
                  <span className={styles.label}>TXID：</span>
                  <span>{getTableCopyText(record.txHash)}</span>
                </div>
                <div className={styles.item}>
                  <span className={styles.label}>{t('normal.orderId')}：</span>
                  <span>{getTableCopyText(record.txId, false)}</span>
                </div>
              </div>
            )
          }
        }}
      />
    </div>
  )
}

export default React.memo(RechargeTable)
