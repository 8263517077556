import React, { useState } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { InputProps } from '@/common/types'
import Input from '@/components/Input'

type Props = InputProps & {
  limit: number
}

const LimitInput: React.FC<Props> = ({
  className,
  limit,
  onChange,
  ...others
}) => {
  const [value, setValue] = useState('')

  return (
    <div className={classNames(styles.limitInput, className)}>
      <Input
        className={styles.input}
        {...others}
        maxLength={limit}
        type="text"
        onChange={(e) => {
          setValue(e.target.value)
          onChange?.(e)
        }}
      />
      <div className={styles.tip}>
        ({value.length}/{limit})
      </div>
    </div>
  )
}

export default React.memo(LimitInput)
