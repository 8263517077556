import React from 'react'
import styles from './style.module.less'
import { FQA as FQAKey } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import { useTranslation } from 'react-i18next'
import Tabs from '@/components/Tabs'
import Help from './Help'
import Feedback from './Feedback'
import type { TabsProps } from 'antd'

const FAQ: React.FC = () => {
  const { t } = useTranslation()

  const formTabs: TabsProps['items'] = [
    {
      key: '1',
      label: t('help.faq'),
      children: <Help />
    },
    {
      key: '2',
      label: t('help.feedback'),
      children: <Feedback />
    }
  ]

  return (
    <Page navigator={FQAKey}>
      <TitleModule
        title={t('help.helpAndReport')}
        more={false}
        className={styles.module}
      >
        <Tabs items={formTabs} className={styles.tabs} />
      </TitleModule>
    </Page>
  )
}

export default React.memo(FAQ)
