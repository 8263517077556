import { useNavigates } from '@/store/useNavigates'
import { useSSOLogin } from '@/store/useSSOLogin'
import { useUserInfo } from '@/store/useUserInfo'
import React, { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

type Props = {
  checkNavigate?: boolean
} & RouteProps

const CustomRoute: React.FC<Props> = ({ checkNavigate = false, ...props }) => {
  const { navigates, hasGotten } = useNavigates()
  const { info } = useUserInfo()
  const { isChecked } = useSSOLogin()

  const checkLogin = useMemo(() => {
    if (props.path === undefined) {
      return false
    }
    if (typeof props.path === 'string') {
      return props.path.startsWith('/profile')
    }
    return props.path.some((item) => item.startsWith('/profile'))
  }, [props.path])

  const show = useMemo(() => {
    if (
      checkNavigate &&
      !navigates.some((n) => n.path === props.path && n.visible)
    ) {
      return false
    }
    if (checkLogin && (!isChecked || !info)) {
      return false
    }
    return true
  }, [navigates, props.path, checkNavigate, isChecked, checkLogin, info])

  if ((checkNavigate && !hasGotten) || (checkLogin && !isChecked)) {
    // 没有请求完
    return null
  }

  return show ? <Route {...props} /> : <Redirect to="/" />
}

export default React.memo(CustomRoute)
