import React, { useEffect, lazy, Suspense } from 'react'
import { HashRouter, Switch } from 'react-router-dom'
import BaseInfo from '@/views/Profile'
import AddressManage from '@/views/Profile/AddressManage'
import AddAddress from '@/views/Profile/AddAddress'
import ProfileAssets from '@/views/Profile/Assets'
import Recharge from '@/views/Profile/Recharge'
import CurrencyRecord from '@/views/Profile/CurrencyRecord'
import CurrencyDetail from '@/views/Profile/CurrencyDetail'
import PointRecord from '@/views/Profile/PointRecord'
import NFTRecord from '@/views/Profile/NFTRecord'
import OtherRecord from './Profile/OtherRecord'
import Setting from './Profile/Setting'
import FAQ from './Profile/FAQ'
import SetAuthenticator from './Profile/SetAuthenticator'
import SetFundsPassword from './Profile/SetFundsPassword'
import Withdraw from './Profile/Withdraw'
import NFTWithdraw from './Profile/NFTWithdraw'
import WithdrawSafetyVerification from './Profile/WithdrawSafetyVerification'
import NFTWithdrawSafetyVerification from './Profile/NFTWithdrawSafetyVerification'
import NFTDetail from './Profile/NFTDetail'
import NFT from './Profile/NFT'
import Items from './Profile/Items'
import ItemDetail from './Profile/ItemDetail'
import SafetyVerification from './Profile/SafetyVerification'
import UpdateEmail from './Profile/UpdateEmail'
import UpdatePassword from './Profile/UpdatePassword'
import DazzleManage from './Profile/DazzleManage'
import Draft from './Profile/Draft'
import DiamondRecharge from './Profile/DiamondRecharge'
import DiamondRechargeRecord from './Profile/DiamondRechargeRecord'
import DiamondConsumeRecord from './Profile/DiamondConsumeRecord'
import Personal from './Profile/Personal'

import Header from '@/components/Header'
import ModalsProvider from '../contexts/Modals'
import { useInviteCode } from 'hooks/useInviteCode'
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useUserInfo } from '@/store/useUserInfo'
import { useMediaInfo } from '@/store/useMediaInfo'
import { WalletApi } from '@/services/wallet'
import { errorNotice } from '@/common/tip'
import styles from './App.module.less'
import { useAssets } from '@/store/useAssets'
import { X_DEVICE_ID } from '@/config'
import { useDownload } from '@/store/useDownload'
import { useEnvInfo } from '@/store/useEnvInfo'
import {
  getLocalItem,
  getSessionItem,
  setLocalItem,
  setSessionItem
} from '@/common/storage'
import { taInit } from '@/common/thinkingdata'

import { GLOBAL_CLICK_TYPE, useEvents } from '@/store/useEvents'

import GlobalBroadcast from '@/components/GlobalBroadcast'
import { config as wagmiConfig } from '@/config/wagmi'
import NFTTransferRecords from './Profile/NFTTransferRecords'
import CustomRoute from '@/components/CustomRoute'
import { useSSOLogin } from '@/store/useSSOLogin'
import { useBaseConfig } from '@/store/useBaseConfig'

const queryClient = new QueryClient()

async function getPublicKey() {
  const res = await WalletApi.getPublicKey()
  if (res.data.code === 0) {
    setSessionItem('PublicKey', res.data.data.publicKey)
  } else {
    errorNotice(res.data.message)
  }
}

const LazyGIPR = lazy(() => import('./GIPR'))
const LazyHome = lazy(() => import('./Home'))
const LazyHomeRanking = lazy(() => import('./HomeRanking'))
const LazyTask = lazy(() => import('./Task'))
const LazyShop = lazy(() => import('./Shop'))
const LazyAppIntro = lazy(() => import('./AppIntro'))
const LazyDazzle = lazy(() => import('./Dazzle'))
const LazyDazzleDetail = lazy(() => import('./DazzleDetail'))
const LazyLive = lazy(() => import('./Live'))
const LazyBest7 = lazy(() => import('./Best7'))
const LazyDazzleUpload = lazy(() => import('./DazzleUpload'))
const LazyInformation = lazy(() => import('./Information'))

const App: React.FC = () => {
  const { initInviteCode } = useInviteCode()
  const { info } = useUserInfo()
  const { resetMediaInfo, fetchMediaInfo } = useMediaInfo()
  const { getAssets } = useAssets()
  const { getURLs } = useDownload()
  useEnvInfo()
  const { getBaseConfig } = useBaseConfig()

  const { authorize } = useSSOLogin()

  useEffect(() => {
    if (!info) {
      resetMediaInfo()
    } else {
      fetchMediaInfo(info.accountId)
    }
  }, [info?.accountId])

  useEffect(() => {
    if (info?.accountId) {
      getAssets(info.accountId)
    }
  }, [info?.accountId])

  function init() {
    // 获取下载页相关链接和二维码地址
    getURLs()

    // 获取公钥
    if (!getSessionItem('PublicKey')) {
      getPublicKey()
    }

    // 初始化邀请码
    initInviteCode()

    // 获取设备id
    const deviceId = getLocalItem(X_DEVICE_ID)
    if (!deviceId) {
      setLocalItem(X_DEVICE_ID, 'PC')
    }

    // 初始化thinking data
    taInit()

    authorize()

    getBaseConfig()
  }

  useEffect(init, [])

  const { execEvents } = useEvents()

  useEffect(() => {
    function handle() {
      execEvents(GLOBAL_CLICK_TYPE)
    }
    document.addEventListener('click', handle, false)
    return () => {
      document.removeEventListener('click', handle)
    }
  }, [])

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <div className={styles.wrapper}>
          <HashRouter>
            <ModalsProvider>
              <div className={styles.layout}>
                <GlobalBroadcast />
                <Header />
                <Suspense>
                  <Switch>
                    <CustomRoute path="/" component={LazyInformation} exact />
                    <CustomRoute
                      path="/gipr2"
                      component={LazyHomeRanking}
                      exact
                    />
                    {/* <CustomRoute path="/live" component={LazyLive} exact /> */}
                    <CustomRoute path="/vote" component={LazyGIPR} exact />
                    <CustomRoute
                      path="/task"
                      component={LazyTask}
                      exact
                      checkNavigate
                    />
                    <CustomRoute
                      path="/shop"
                      component={LazyShop}
                      exact
                      checkNavigate
                    />
                    <CustomRoute path="/app" component={LazyAppIntro} exact />
                    <CustomRoute
                      path="/dazzle"
                      component={LazyDazzle}
                      exact
                      checkNavigate
                    />
                    <CustomRoute path="/best7" component={LazyBest7} exact />
                    <CustomRoute
                      path="/dazzle-detail"
                      component={LazyDazzleDetail}
                      exact
                    />
                    {/* <CustomRoute
                      path="/dazzle-upload"
                      component={LazyDazzleUpload}
                      exact
                    /> */}
                    <CustomRoute
                      path="/profile/base-info"
                      component={BaseInfo}
                      exact
                    />
                    <CustomRoute path="/personal" component={Personal} exact />
                    <CustomRoute
                      path="/profile/address-manage"
                      component={AddressManage}
                      exact
                    />
                    <CustomRoute
                      path="/profile/add-address"
                      component={AddAddress}
                      exact
                    />
                    <CustomRoute
                      path="/profile/assets"
                      component={ProfileAssets}
                      exact
                    />
                    <CustomRoute
                      path="/profile/recharge"
                      component={Recharge}
                      exact
                    />
                    <CustomRoute
                      path="/profile/diamond-recharge"
                      component={DiamondRecharge}
                      exact
                    />
                    <CustomRoute
                      path="/profile/diamond-recharge-record"
                      component={DiamondRechargeRecord}
                      exact
                    />
                    <CustomRoute
                      path="/profile/diamond-consume-record"
                      component={DiamondConsumeRecord}
                      exact
                    />
                    <CustomRoute
                      path="/profile/currency-record"
                      component={CurrencyRecord}
                      exact
                    />
                    <CustomRoute
                      path="/profile/withdraw"
                      component={Withdraw}
                      exact
                    />
                    <CustomRoute
                      path="/profile/nft-withdraw"
                      component={NFTWithdraw}
                      exact
                    />
                    <CustomRoute
                      path="/profile/currency-detail"
                      component={CurrencyDetail}
                      exact
                    />
                    <CustomRoute
                      path="/profile/video-manage"
                      component={DazzleManage}
                      exact
                    />
                    <CustomRoute
                      path="/profile/draft"
                      component={Draft}
                      exact
                    />
                    <CustomRoute
                      path="/profile/point-record"
                      component={PointRecord}
                      exact
                    />
                    <CustomRoute
                      path="/profile/nft-record"
                      component={NFTRecord}
                      exact
                    />
                    <CustomRoute
                      path="/profile/other-record"
                      component={OtherRecord}
                      exact
                    />
                    <CustomRoute
                      path="/profile/nft-transfer-record"
                      component={NFTTransferRecords}
                      exact
                    />
                    <CustomRoute
                      path="/profile/setting"
                      component={Setting}
                      exact
                    />
                    <CustomRoute
                      path="/profile/help-and-feedback"
                      component={FAQ}
                      exact
                    />
                    <CustomRoute
                      path="/profile/set-funds-password"
                      component={SetFundsPassword}
                      exact
                    />
                    <CustomRoute
                      path="/profile/set-authenticator"
                      component={SetAuthenticator}
                      exact
                    />
                    <CustomRoute path="/profile/nft" component={NFT} exact />
                    <CustomRoute
                      path="/profile/items"
                      component={Items}
                      exact
                    />
                    <CustomRoute
                      path="/profile/nft-detail"
                      component={NFTDetail}
                      exact
                    />
                    <CustomRoute
                      path="/profile/item-detail"
                      component={ItemDetail}
                      exact
                    />
                    <CustomRoute
                      path="/profile/safety-verification"
                      component={SafetyVerification}
                      exact
                    />
                    <CustomRoute
                      path="/profile/withdraw-safety-verification"
                      component={WithdrawSafetyVerification}
                      exact
                    />
                    <CustomRoute
                      path="/profile/nft-withdraw-safety-verification"
                      component={NFTWithdrawSafetyVerification}
                      exact
                    />
                    <CustomRoute
                      path="/profile/update-email"
                      component={UpdateEmail}
                      exact
                    />
                    <CustomRoute
                      path="/profile/update-password"
                      component={UpdatePassword}
                      exact
                    />
                  </Switch>
                </Suspense>
              </div>
            </ModalsProvider>
          </HashRouter>
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default App
