import React from 'react'
import { Page } from '../components'
import { ASSETS } from '@/constants/profile'
import Form from './Form'
import Table from './Table'

const DiamondRecharge: React.FC = () => {
  return (
    <Page navigator={ASSETS}>
      <Form />
      <Table />
    </Page>
  )
}

export default React.memo(DiamondRecharge)
