import React from 'react'
import styles from './style.module.less'
import { Skeleton } from 'antd'
import ImgSkeleton from '@/components/ImgSkeleton'

const NFTItemSkeleton: React.FC = () => {
  return (
    <div className={styles.item}>
      <ImgSkeleton className={styles.imgCtn} />
      <div className={styles.detail}>
        <div className={styles.name}></div>
        <div className={styles.bottom}>
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
      </div>
    </div>
  )
}

export default NFTItemSkeleton
