import React, { useMemo } from 'react'
import styles from './style.module.less'
import { useUserInfo } from '@/store/useUserInfo'
import {
  DiscordBind,
  DiscordUnbind,
  TwitterBind,
  TwitterUnbind
} from '@/views/Profile/image'
import { Space } from 'antd'
import { CameraIcon } from '@/assets'
import { useTranslation } from 'react-i18next'
import { useMediaInfo } from '@/store/useMediaInfo'
import TextCopy from '@/components/TextCopy'
import AvatarUpload from '../AvatarUpload'
import { getMediaSrc } from '@/utils/utils'

const BaseInfo: React.FC = () => {
  const { info } = useUserInfo()
  const { t } = useTranslation()
  const { mediaInfo } = useMediaInfo()

  return (
    <div className={styles.baseInfo}>
      <AvatarUpload>
        <div className={styles.avatarCtn}>
          <img src={getMediaSrc(info?.avatar)} className={styles.img} />
          <img src={CameraIcon} className={styles.camera} />
        </div>
      </AvatarUpload>
      <div className={styles.right}>
        <span className={styles.upload}>{info?.userName}</span>
        <div className={styles.id}>
          <span>{t('profile.userId')}</span>
          {info?.uid && (
            <TextCopy text={`${info.uid}`} className={styles.copy} />
          )}
        </div>
        <Space size={10}>
          <img
            src={mediaInfo?.twitterToken ? TwitterBind : TwitterUnbind}
            className={styles.icon}
          />
          <img
            src={mediaInfo?.discordToken ? DiscordBind : DiscordUnbind}
            className={styles.icon}
          />
        </Space>
      </div>
    </div>
  )
}

export default React.memo(BaseInfo)
