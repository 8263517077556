import React from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { ITEMS_MANAGE } from '@/constants/profile'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { WarningIcon } from '@/assets'
import {
  debouncePromise,
  getLangData,
  getMediaSrc,
  getSearchParams
} from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import { UserAPI } from '@/services/userAPI'
import { DefaultNFT, DetailBox } from '../image'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { useModal } from '@/store/useModal'
import BlindBoxBatchOpenModal from './BlindBoxBatchOpenModal'

const ItemDetail: React.FC = () => {
  const params = getSearchParams()
  const { open } = useModal()

  const { data, refresh } = useQuery(() =>
    UserAPI.getNFTDetail({
      id: params.id,
      tokenId: params.tokenId
    })
  )

  const history = useHistory()
  const { t } = useTranslation()

  const name = getLangData(data?.goodsNameI18n)
  const src = getMediaSrc(data?.resources[0]?.resourceUrl) || DefaultNFT

  async function handleOpen() {
    if (data) {
      open(<BlindBoxBatchOpenModal goods={data} refresh={refresh} />)
    }
  }

  return (
    <Page navigator={ITEMS_MANAGE}>
      <Module>
        <div className={styles.header}>
          <GoBackTitle
            title={name}
            onClick={() => history.push('/profile/items')}
          />
          {/* <Button className={styles.btn}>转出NFT</Button> */}
        </div>
        <div className={styles.detail}>
          <div className={styles.posterCtn}>
            <img src={DetailBox} className={styles.locationIcon} />
            <div className={styles.box}>
              <img className={styles.poster} src={src} />
            </div>
          </div>
          <div className={styles.infoCtn}>
            <div>
              <div className={styles.label}>{t('profile.basicInfo')}</div>
              <div className={styles.title}>{name}</div>
              <div className={styles.line}>
                <div className={styles.label}>
                  {t('profile.redemptionPrice')}
                </div>
                <div>
                  {data ? `${data.exchangePrice} ${data.coinCode}` : '-'}
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.label}>{t('profile.quantity')}</div>
                <div>{data?.quantityHeld ?? '-'}</div>
              </div>
              {/* <div className={styles.line}>
                    <div className={styles.label}>{t('profile.redemptionTime')}</div>
                    <div>{data ? formatTime(data?.createdTime) : '-'}</div>
                  </div>
                  <div className={styles.line}>
                    <div className={styles.label}>{t('profile.orderId')}</div>
                    <div>{data?.orderSn ?? '-'}</div>
                  </div>  
                  <div className={styles.line}>
                    <div className={styles.label}>网络</div>
                    <div>{chainName ?? '-'}</div>
                  </div>
                  <div className={styles.line}>
                    <div className={styles.label}>合约地址</div>
                    <div>{data?.contractAddress ?? '-'}</div>
                  </div>
                  <div className={styles.line}>
                    <div className={styles.label}>代币ID</div>
                    <div>{params.tokenId}</div>
                  </div> */}
            </div>
            {data?.openBox === 1 && (
              <Button
                className={styles.openBtn}
                onClick={debouncePromise(handleOpen)}
              >
                {t('profile.openBox')}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.tipCtn}>
          <img src={WarningIcon} className={styles.warningIcon} />
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: getLangData(data?.goodsDescribeI18n)
            }}
          ></div>
        </div>
      </Module>
    </Page>
  )
}

export default React.memo(ItemDetail)
