import { SupportChain } from '@/store/useBaseConfig'
import React from 'react'
import { createContext, useContext, useReducer, useEffect } from 'react'

// 定义状态和动作类型
interface ChainState {
  chains: SupportChain[]
  selectedChain: SupportChain | null
}

type ChainAction =
  | { type: 'SET_CHAINS'; payload: SupportChain[] }
  | { type: 'SELECT_CHAIN'; payload: string }

// 创建上下文
const ChainContext = createContext<
  | {
      state: ChainState
      dispatch: React.Dispatch<ChainAction>
    }
  | undefined
>(undefined)

// 状态管理 Reducer
function chainReducer(state: ChainState, action: ChainAction): ChainState {
  switch (action.type) {
    case 'SET_CHAINS':
      return {
        ...state,
        chains: action.payload,
        selectedChain: action.payload[0] || null
      }
    case 'SELECT_CHAIN': {
      const selectedChain =
        state.chains.find((chain) => chain.chainId === action.payload) || null
      return { ...state, selectedChain }
    }
    default:
      return state
  }
}

// 自定义 Provider 组件
export function ChainProvider({
  children,
  initialChains = [],
  onChainChange
}: {
  children: React.ReactNode
  initialChains?: SupportChain[]
  onChainChange?: (chain: SupportChain) => void
}) {
  const [state, dispatch] = useReducer(chainReducer, {
    chains: initialChains,
    selectedChain: initialChains[0] || null
  })

  // 监听链变化
  useEffect(() => {
    if (state.selectedChain && onChainChange) {
      onChainChange(state.selectedChain)
    }
  }, [state.selectedChain, onChainChange])

  return (
    <ChainContext.Provider value={{ state, dispatch }}>
      {children}
    </ChainContext.Provider>
  )
}

// 自定义 Hook
export function useChainContext() {
  const context = useContext(ChainContext)
  if (!context) {
    throw new Error('useChainContext must be used within a ChainProvider')
  }
  return context
}
