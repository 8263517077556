import { getLocalItem, getSessionItem } from '@/common/storage'
import { WEB3_TYPE } from '@/constants/user'
import { useUserInfo } from '@/store/useUserInfo'
import { InternalAxiosRequestConfig } from 'axios'
import { getChainId } from 'wagmi/actions'
import { config as wagmiConfig } from '@/config/wagmi'
import { useBaseConfig } from '@/store/useBaseConfig'
import { rsaEncrypt } from '@/utils/utils'
import { DEVICE_TYPE, X_DEVICE_ID } from '@/config'
import i18next from 'i18next'

type Config = InternalAxiosRequestConfig<any>

function setToken(config: Config) {
  const token = getSessionItem('token')
  if (token) {
    config.headers.set('token', token)
  }
}

function getBaseURL() {
  if (process.env.NODE_ENV === 'development') {
    return ''
  }
  if (process.env.isPre) {
    return 'https://meetapi-v3.meet48.xyz/meet48-api'
  }
  return process.env.isTestOrDev
    ? 'https://betatest-meetapi-v1.meet48.xyz/meet48-api'
    : 'https://meetapi-v2.meet48.xyz/meet48-api'
}

function updateURL(config: Config) {
  config.url = getBaseURL() + config.url
}

function setChainHeaders(config: Config) {
  if (useUserInfo.getState().info?.type !== WEB3_TYPE) {
    return
  }
  const chainId = getChainId(wagmiConfig)
  if (chainId) {
    config.headers.set('X-Chain-Id', chainId)
  }

  const chainCode = useBaseConfig
    .getState()
    .baseConfig.supportChainInfos?.find(
      (item) => +item.chainId === chainId
    )?.chainCode

  if (chainCode) {
    config.headers.set('X-Chain-Code', chainCode)
  }
}

function setContentType(config: Config) {
  config.headers.set('Content-Type', 'application/json;charset=UTF-8')
  if (config.headers.needEncrypt) {
    config.headers.set('Content-Type', 'text/plain')
    config.data = rsaEncrypt(JSON.stringify(config.data))
    config.headers.delete('needEncrypt')
  }
  if (config.headers.isFormData) {
    config.headers.set('Content-Type', 'multipart/form-data')
    config.headers.delete('isFormData')
  }
}

function setCommonHeaders(config: Config) {
  config.headers.set('X-Custom-Device-Type', DEVICE_TYPE)
  config.headers.set('X-VersionCode', '1.0.0')

  const deviceId = getLocalItem(X_DEVICE_ID)

  if (typeof deviceId === 'string') {
    config.headers.set(X_DEVICE_ID, deviceId)
  }

  config.headers.set('Accept-Language', i18next.language)
}

export function processConfig(config: Config) {
  setToken(config)
  updateURL(config)
  setChainHeaders(config)
  setContentType(config)
  setCommonHeaders(config)
}
