import React, { useEffect } from 'react'
import styles from './style.module.less'
import { useUserInfo } from '@/store/useUserInfo'
import InputBox from '@/components/InputBox'
import Select from '@/components/Select'
import { LabelDatePicker } from '@/components/DatePicker'
import { Address } from '../components'
import { Button, ProfileButton } from '@/components/Button'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BaseInfo from './BaseInfo'
import { checkUsername } from '@/common/login'
import { UserInfo, WalletApi } from '@/services/wallet'
import { errorNotice, notice } from '@/common/tip'
import { Form, Space } from 'antd'
import dayjs from 'dayjs'

// 使用dayjs设置datepicker的初始值时，编辑日期时可能报错，通过以下方法解决
// 参考链接：https://github.com/ant-design/ant-design/issues/26190
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import Medias from './Medias'
import useQuery from '@/hooks/useQuery'
import { RangePickerProps } from 'antd/lib/date-picker'
import { WEB3_TYPE } from '@/constants/user'
import { ApiResponse } from '@/services/common'

dayjs.extend(weekday)
dayjs.extend(localeData)

const { LabelInput } = InputBox

const Personal: React.FC = () => {
  const { info, save, update } = useUserInfo()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()

  const { data: userInfo } = useQuery(
    () =>
      info?.accountId
        ? WalletApi.getUserInfo({ accountId: info.accountId })
        : Promise.resolve({} as ApiResponse<UserInfo>),
    [info?.accountId]
  )

  useEffect(() => {
    if (!userInfo || !info) {
      return
    }
    update({
      ...info,
      ...userInfo,
      type: userInfo.type.toString(),
      address: info.address
    })
    form.setFieldsValue({
      userName: userInfo.userName,
      gender: Number(userInfo.gender),
      birthdate: userInfo.birthday
        ? dayjs(+new Date(userInfo.birthday))
        : undefined
    })
  }, [userInfo])

  const { data } = useQuery(() => {
    return WalletApi.getUserWithdrawAddressList({
      pageSize: 2,
      pageNo: 1
    })
  })

  const { records: addressList = [] } = data ?? {}

  async function updateInfo() {
    const { userName, gender, birthdate } = form.getFieldsValue()
    const birthday = birthdate?.valueOf()
    if (!checkUsername(userName, 2)) {
      return
    }
    if (birthdate && +birthdate.toDate() > Date.now()) {
      return errorNotice(t('profile.dateRangeError'))
    }
    const res = await WalletApi.updateAccountInfo({
      // 用户名如果没改，则不传或传空字符串
      userName: userName === info!.userName ? '' : userName,
      birthdate: birthday ? dayjs(birthday).format('YYYY/MM/DD') : undefined,
      gender: gender
    })
    if (res.data.code === 0) {
      notice(t('personal.saveSuccess'), 'success')
      // 修改成功更新用户信息到 store 及 sessionStorage
      save({ ...info!, userName, gender, birthday })
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Module className={styles.personal}>
      <div className={styles.title}>{t('personal.personal')}</div>
      <div className={styles.baseInfo}>
        <Form form={form} onFinish={updateInfo}>
          <BaseInfo />
          <div className={styles.form}>
            <Form.Item
              key="userName"
              name="userName"
              noStyle
              initialValue={info?.userName}
            >
              <LabelInput
                label={t('profile.username')}
                placeholder={t('profile.usernameRequired')}
              />
            </Form.Item>
            {info?.type === WEB3_TYPE && (
              <LabelInput
                label={t('personal.walletAddress')}
                disabled
                defaultValue={info?.address}
              />
            )}
            <Form.Item name="gender" noStyle initialValue={info?.gender}>
              <Select
                label={t('normal.gender')}
                options={[
                  { value: 0, label: t('normal.male') },
                  { value: 1, label: t('normal.female') },
                  { value: 2, label: t('normal.unknown') }
                ]}
              />
            </Form.Item>
            <Form.Item
              name="birthdate"
              noStyle
              initialValue={
                info?.birthday ? dayjs(+new Date(info.birthday)) : undefined
              }
            >
              <LabelDatePicker
                format="YYYY/MM/DD"
                label={t('normal.birthday')}
                placeholder={t('normal.unknown')}
                allowClear={false}
                onChange={(date) => {
                  if (date && +date.toDate() > Date.now()) {
                    errorNotice(t('profile.dateRangeError'))
                  }
                }}
              />
            </Form.Item>
            {/* <Form.Item name="gender" noStyle>
              <LabelInput label="邀请绑定" placeholder="未绑定" />
            </Form.Item> */}
          </div>
          <div className={styles.withdrawCtn}>
            <div className={styles.title}>
              {t('wallet.address.paymentMethod')}
            </div>
            <div className={styles.withdrawHeader}>
              <div className={styles.subTitle}>
                {t('wallet.address.addressManage')}
              </div>
              <Space>
                <span>{t('wallet.address.addressManageTip')}</span>
                <ProfileButton
                  onClick={() => history.push('/profile/address-manage')}
                >
                  {t('wallet.address.manage')}
                </ProfileButton>
              </Space>
            </div>
            <div className={styles.addresses}>
              {addressList.map((address) => {
                return (
                  <Address
                    data={address}
                    key={address.id}
                    operational={false}
                    selectable={false}
                  />
                )
              })}
            </div>
          </div>
          <div className={styles.mediaInfo}>
            <div className={styles.title}>{t('profile.bindMedia')}</div>
            <Medias />
          </div>
          <Button className={styles.btn} type="submit" disabled={!info}>
            {t('profile.save')}
          </Button>
        </Form>
      </div>
    </Module>
  )
}

export default React.memo(Personal)
