import React, { useState } from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { useTranslation } from 'react-i18next'
import { ModalProps } from '@/components/Modal'
import { Button } from '@/components/Button'
import { errorNotice, notice } from '@/common/tip'
import InputNumber from '@/components/InputNumber'
import { useUserInfo } from '@/store/useUserInfo'
import { NFTDetail, UserAPI } from '@/services/userAPI'
import { getLangData } from '@/utils/utils'
import OpenBlindBoxSuccessModal from '@/views/Shop/components/OpenBlindBoxSuccessModal'
import { useModal } from '@/store/useModal'
import { WEB2_TYPE } from '@/constants/user'

type Props = {
  goods: NFTDetail
  refresh: () => void
} & ModalProps

const BlindBoxBatchOpenModal: React.FC<Props> = ({
  goods,
  onDismiss,
  refresh
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('1')
  const [loading, setLoading] = useState(false)
  const { info } = useUserInfo()
  const { open } = useModal()

  const handleCancel = () => {
    onDismiss?.()
  }

  async function handleOpen() {
    setLoading(true)
    const res = await UserAPI.openBlindBox({
      type: 1,
      goodsId: goods.goodsId,
      openNum: Number(value)
    })
    setLoading(false)
    if (res.data.code === 0) {
      if (info?.type === WEB2_TYPE) {
        open(<OpenBlindBoxSuccessModal data={res.data.data} />)
      } else {
        notice(t('normal.success'), 'success')
      }
      refresh()
      onDismiss?.()
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <ModalWrapper
      title={`${getLangData(goods.goodsNameI18n)}`}
      onDismiss={onDismiss}
      width={407}
    >
      <div className={styles.ctn}>
        <div className={styles.detail}>
          <div className={styles.input}>
            <InputNumber
              value={value}
              onChange={(val) => setValue(val)}
              min={1}
              limit={Math.min(99, goods.quantityHeld)}
            />
          </div>
          <div className={styles.quantityTip}>
            {t('profile.itemDetail.max')} {goods.quantityHeld}{' '}
            {t('profile.itemDetail.vol')}
          </div>
        </div>
        <div className={styles.btnRow}>
          <Button
            className={styles.btn}
            onClick={handleCancel}
            disabled={loading}
            borderOnly
          >
            {t('normal.cancel')}
          </Button>
          <Button
            className={styles.btn}
            onClick={handleOpen}
            disabled={loading}
            loading={loading}
            loadingText={t('shop.btn.openLoading')}
          >
            {t('normal.ok')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default React.memo(BlindBoxBatchOpenModal)
