import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import { ORDER, ORDER_NFT } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import Select from '@/components/Select'
import { StyledRangePicker } from '@/components/DatePicker'
import { Form, Space, TableProps } from 'antd'
import { ProfileButton } from '@/components/Button'
import { ProfileTable } from '@/components/Table'
import { DownArrow } from '@/assets'
import classNames from 'classnames'
import { useOrderOptions } from '@/store/useOrderOptions'
import { useTranslation } from 'react-i18next'
import {
  ellipsisText,
  formatTime,
  getLangData,
  getMediaSrc
} from '@/utils/utils'
import {
  ExchangeRecord,
  NFTRecord as NFTRecordType,
  ShopAPI
} from '@/services/shopAPI'
import { errorNotice } from '@/common/tip'
import TextCopy from '@/components/TextCopy'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { getDayEnd, getDayStart } from '@/common/profile'
import ReceiveBtn from './ReceiveBtn'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB3_TYPE } from '@/constants/user'

const CLASS_TYPE = 2
const PAGE_SIZE = 10

const NFTRecord: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  const [dataSource, setDataSource] = useState<ExchangeRecord[]>([])

  const { allOptions, getOptions, getOptionByType } = useOrderOptions()
  const { i18n } = useTranslation()
  const [total, setTotal] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const { info } = useUserInfo()

  useEffect(() => {
    if (allOptions.length === 0) {
      getOptions()
    }
  }, [allOptions.length])

  const options = useMemo(() => {
    return getOptionByType(CLASS_TYPE).map((item) => ({
      label: getLangData(item.orderSearchNameI18n),
      value: item.orderSearchType
    }))
  }, [allOptions, i18n.language])

  async function getList() {
    const { range, searchType } = form.getFieldsValue()
    const startTime = getDayStart(range?.[0]?.valueOf())
    const endTime = getDayEnd(range?.[1]?.valueOf())
    const res = await ShopAPI.getExchangeRecords<NFTRecordType>({
      pageNo,
      pageSize: PAGE_SIZE,
      classType: CLASS_TYPE,
      searchType,
      startTime,
      endTime
    })
    if (res.data.code === 0) {
      setDataSource(res.data.data.records)
      setTotal(+res.data.data.total)
    } else {
      errorNotice(res.data.message)
    }
  }

  useEffect(() => {
    getList()
  }, [pageNo])

  function onExpand(id: string) {
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys((keys) => keys.filter((key) => key !== id))
    } else {
      setExpandedRowKeys((keys) => [...keys, id])
    }
  }

  const columns: TableProps<NFTRecordType>['columns'] = [
    {
      title: t('profile.works'),
      dataIndex: 'goodsNameI18n',
      key: 'goodsNameI18n',
      render(val, item) {
        return (
          <div className={styles.goodsNameCtn}>
            <img src={getMediaSrc(item.showImageUrl)} className={styles.img} />
            <span>{getLangData(item.goodsNameI18n)}</span>
          </div>
        )
      }
    },
    {
      title: t('profile.currency'),
      dataIndex: 'currencyTypeName',
      key: 'currencyTypeName'
    },
    {
      title: t('profile.price'),
      dataIndex: 'amount',
      width: 100,
      key: 'amount'
    },
    {
      title: t('profile.time'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render(val, item) {
        return (
          <div className={styles.time}>{formatTime(+item.createdTime)}</div>
        )
      }
    },
    {
      title: t('profile.orderId'),
      dataIndex: 'orderSn',
      key: 'orderSn',
      width: 200,
      render(val, item) {
        return (
          <div className={styles.orderIdCtn}>
            <TextCopy text={val} />
            {info?.type !== WEB3_TYPE && (
              <img
                src={DownArrow}
                className={classNames(
                  styles.arrow,
                  expandedRowKeys.includes(item.id) && styles.expandedArrow
                )}
                onClick={() => onExpand(item.id)}
              />
            )}
          </div>
        )
      }
    },
    ...(info?.type === WEB3_TYPE
      ? [
          {
            title: t('profile.receive'),
            dataIndex: 'nftContract',
            render(val: any, item: NFTRecordType) {
              return (
                <div className={styles.orderIdCtn}>
                  <ReceiveBtn data={item} getList={getList} />
                  <img
                    src={DownArrow}
                    className={classNames(
                      styles.arrow,
                      expandedRowKeys.includes(item.id) && styles.expandedArrow
                    )}
                    onClick={() => onExpand(item.id)}
                  />
                </div>
              )
            }
          }
        ]
      : [])
  ]

  function handleReset() {
    form.resetFields()
  }

  return (
    <Page navigator={`${ORDER}/${ORDER_NFT}`}>
      <TitleModule
        title={t('profile.nftRecord')}
        more={false}
        className={styles.module}
      >
        <div className={styles.search}>
          <Form form={form} onFinish={getList}>
            <Space size={12}>
              <Form.Item noStyle name="range">
                <StyledRangePicker
                  className={styles.formItem}
                  placeholder={[t('profile.startTime'), t('profile.endTime')]}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
              <Form.Item noStyle name="searchType" initialValue={0}>
                <Select className={styles.formItem} options={options} />
              </Form.Item>
              <ProfileButton
                className={styles.btn}
                type="submit"
                onClick={() => setPageNo(1)}
              >
                {t('dazzle.search')}
              </ProfileButton>
              <ProfileButton className={styles.btn} onClick={handleReset}>
                {t('profile.reset')}
              </ProfileButton>
            </Space>
          </Form>
        </div>
        <div className={styles.tableCtn}>
          <Empty data={dataSource}>
            <ProfileTable
              expandedRowKeys={expandedRowKeys}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              rowKey="id"
              expandable={{
                expandIcon() {
                  return null
                },
                expandedRowRender(record) {
                  return (
                    <div className={styles.extraRow}>
                      <div className={styles.item}>
                        <span className={styles.label}>
                          {t('profile.network')}：
                        </span>
                        <span>{record.chainName}</span>
                      </div>
                      <div className={styles.item}>
                        <span className={styles.label}>
                          {t('profile.quantity')}：
                        </span>
                        <span>{record.quantity}</span>
                      </div>
                      <Space className={styles.item}>
                        <span className={styles.label}>TXID：</span>
                        <TextCopy text={record.tradeHash}>
                          {ellipsisText(record.tradeHash, {
                            head: 15,
                            tail: 15
                          })}
                        </TextCopy>
                      </Space>
                    </div>
                  )
                }
              }}
            />
            <div className={styles.paginationCtn}>
              <Pagination
                total={total}
                showSizeChanger={false}
                onChange={(page) => setPageNo(page)}
                current={pageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        </div>
      </TitleModule>
    </Page>
  )
}

export default React.memo(NFTRecord)
