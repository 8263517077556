import { decryptData } from '@/common/dataConvert'
import { errorNotice } from '@/common/tip'
import { WalletApi } from '@/services/wallet'
import { useEffect, useState } from 'react'

export default function useRate(sendCoin: string) {
  const [rate, setRate] = useState(0)

  const [rateLoading, setRateLoading] = useState(false)

  async function getRelation() {
    setRateLoading(true)
    const res = await WalletApi.getCoinDiamondRelation(sendCoin)
    setRateLoading(false)
    if (res.data.code === 0) {
      setRate(decryptData(res.data.data).exchangeRate)
    } else {
      errorNotice(res.data.message)
    }
  }

  useEffect(() => {
    const timer = setInterval(getRelation, 60000)
    return () => {
      clearInterval(timer)
    }
  }, [sendCoin])

  useEffect(() => {
    getRelation()
  }, [sendCoin])

  return { rate, rateLoading }
}
