import { AxiosResponse } from 'axios'

export type BaseResponse<T> = {
  code: number
  message: string
  data: T
  success: boolean
}

export type ListResponse<T> = {
  pageNum: number
  pageSize: number
  total: string
} & T

export type Pagination = {
  pageNo: number
  pageSize: number
}

export type ApiResponse<T> = AxiosResponse<BaseResponse<T>>

export type PromiseResponse<T> = Promise<ApiResponse<T>>

export type ApiListResponse<T> = AxiosResponse<BaseResponse<ListResponse<T>>>

export type PromiseListResponse<T> = Promise<ApiListResponse<T>>

export type ExcludeKeys<T, U> = Pick<T, Exclude<keyof T, keyof U>>

export type BaseParams = {
  accountId: string
  languageType: string
}

export function replaceToV2(path: string) {
  return path.replace('v1', 'v2')
}
