import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { Page, TitleModule } from '../components'
import { NFT_MANAGE } from '@/constants/profile'
import { Space, TableProps } from 'antd'
import { ProfileButton } from '@/components/Button'
import Item from './Item'
import Switch from './Switch'
import { ProfileTable } from '@/components/Table'
import ColorfulText from '@/components/ColorfulText'
import { NFT as NFTType } from '@/services/userAPI'
import { getLangData, getMediaSrc } from '@/utils/utils'
import { DefaultNFT } from '../image'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { useFetchNFT } from '@/hooks/web3/useFetchNFT'
import { ExtendNFT } from '@/services/wallet'
import NFTItemSkeleton from './NFTItemSkeleton'

const PAGE_SIZE = 12

const Web3NFT: React.FC = () => {
  const [isDetail, setIsDetail] = useState(true)
  const [nfts, setNFTs] = useState<ExtendNFT[]>([])
  const history = useHistory()
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const { extendData, isLoading } = useFetchNFT()

  function toDetail(id: string, tokenId: string) {
    history.push(`/profile/nft-detail?id=${id}&tokenId=${tokenId}`)
  }

  function toWithdraw(nftId: string, tokenId: string) {
    history.push(`/profile/nft-withdraw?nftId=${nftId}&tokenId=${tokenId}`)
  }

  const columns: TableProps<NFTType>['columns'] = [
    {
      title: t('profile.icon'),
      dataIndex: 'showImageUrl',
      key: 'showImageUrl',
      align: 'center',
      width: 100,
      render(_val, item) {
        const src = getMediaSrc(item.showImageUrl) || DefaultNFT
        return <img className={styles.avatar} src={src} />
      }
    },
    {
      title: t('profile.name'),
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 200,
      render(_val, item) {
        return getLangData(item.goodsNameI18n)
      }
    },
    {
      title: t('profile.price'),
      dataIndex: 'exchangePrice',
      key: 'exchangePrice',
      render(_val, item) {
        return (
          <ColorfulText
            text={`${item.exchangePrice} ${t('gifr.pick')}`}
            colors={['#9A6BFE', '#FC78FF']}
            inline
            className={styles.price}
          />
        )
      }
    },
    {
      title: t('profile.operate'),
      dataIndex: 'operate',
      key: 'operate',
      align: 'center',
      width: 100,
      render(_val, item) {
        return (
          <Space size={14}>
            <ProfileButton onClick={() => toDetail(item.id, item.tokenId)}>
              {t('wallet.nft.detail')}
            </ProfileButton>
            {item.withdrawEnable === 1 && (
              <ProfileButton onClick={() => toWithdraw(item.id, item.tokenId)}>
                {t('wallet.nft.transfer')}
              </ProfileButton>
            )}
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    if (extendData) {
      setNFTs(extendData)
      setTotal(extendData.length)
    }
  }, [extendData])

  return (
    <Page navigator={NFT_MANAGE}>
      <TitleModule
        title={t('profile.myNFT')}
        more={false}
        className={styles.module}
      >
        <div className={styles.header}>
          <Switch open={isDetail} onClick={() => setIsDetail((o) => !o)} />
        </div>
        {isLoading && isDetail ? (
          <div className={styles.list}>
            {[1, 2, 3, 4].map((item) => (
              <NFTItemSkeleton key={item} />
            ))}
          </div>
        ) : (
          <Empty data={nfts}>
            {isDetail ? (
              <div className={styles.list}>
                {nfts.map((nft) => {
                  return (
                    <Item
                      key={`${nft.contractAddress}_${nft.tokenId}`}
                      data={{
                        goodsNameI18n: nft.goodsNameI18n,
                        showImageUrl: nft.showImageUrl,
                        tokenId: nft.tokenId,
                        id: nft.nftId.toString(),
                        exchangePrice: nft.exchangePrice,
                        withdrawEnable: nft.withdrawEnable
                      }}
                    />
                  )
                })}
              </div>
            ) : (
              <ProfileTable
                dataSource={nfts}
                columns={columns}
                pagination={false}
                rowKey={(record) =>
                  `${record.contractAddress}_${record.tokenId}`
                }
              />
            )}
            <div className={styles.paginationCtn}>
              <Pagination
                total={total}
                showSizeChanger={false}
                onChange={(page) => setPageNo(page)}
                current={pageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        )}
      </TitleModule>
    </Page>
  )
}

export default React.memo(Web3NFT)
