import React, { useEffect, useMemo } from 'react'
import styles from './style.module.less'
import { TickSvg, DownArrow } from '@/assets'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg'
import { useAccount, useChainId } from 'wagmi'
import { getEllipsisText } from '@/utils/utils'
import { useChainSwitch } from '@/hooks/web3/useChainSwitch'
import { useBaseConfig } from '@/store/useBaseConfig'
import useWalletConnect from '@/hooks/web3/useWalletConnect'

type Props = {
  className?: string
  chainListClassName?: string
  eclipseLength?: number
  currentEclipseLength?: number
  selectedChainNameLength?: number
  selectedChainClassName?: string
}

const ChainSwitch: React.FC<Props> = ({
  className = '',
  chainListClassName = '',
  selectedChainClassName = '',
  selectedChainNameLength = 6,
  eclipseLength = 24,
  currentEclipseLength = 12
}) => {
  const chainId = useChainId()
  const { switchCurrentChain } = useChainSwitch()
  const { onConnect } = useWalletConnect()
  const { isConnected } = useAccount()
  const { baseConfig } = useBaseConfig()
  const SUPPORTED_CHAINS = baseConfig.supportChainInfos

  const selectedChain = useMemo(() => {
    return (
      SUPPORTED_CHAINS?.find(
        (chain) => chain.chainId === chainId?.toString()
      ) || SUPPORTED_CHAINS?.[0]
    )
  }, [chainId, SUPPORTED_CHAINS])

  const eclipseChainName = useMemo(() => {
    return getEllipsisText(
      selectedChain?.chainName || '',
      selectedChainNameLength
    )
  }, [selectedChain])

  const handleClick = async (chainId: string) => {
    if (!isConnected) {
      const success = await onConnect()
      if (!success) {
        return
      }
    }
    switchCurrentChain(Number(chainId))
  }

  return (
    <div className={classNames(styles.switch, className)}>
      <div className={classNames(styles.selectedChain, selectedChainClassName)}>
        <div className={styles.chainInfo}>
          <img src={selectedChain?.chainIcon} className={styles.icon} />
          <span title={selectedChain?.chainName}>{eclipseChainName}</span>
        </div>
        <img className={styles.arrow} src={DownArrow} alt="down-arrow" />
      </div>
      <div className={classNames(styles.chainListCtn, chainListClassName)}>
        <div className={styles.chainList}>
          {(SUPPORTED_CHAINS || []).map((chain) => (
            <div
              key={chain.chainId}
              className={classNames(
                styles.chainItem,
                chain.chainId === selectedChain?.chainId && styles.active
              )}
              onClick={(e) => {
                e.stopPropagation()
                handleClick(chain.chainId)
              }}
            >
              <div className={styles.chainInfo}>
                <img src={chain?.chainIcon} className={styles.icon} />
                <span>
                  {getEllipsisText(
                    chain.chainName,
                    chain.chainId === selectedChain?.chainId
                      ? currentEclipseLength
                      : eclipseLength
                  )}
                </span>
              </div>
              {chain.chainId === selectedChain?.chainId && (
                <ReactSVG src={TickSvg} className={styles.tick} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ChainSwitch)
