import React, { useRef } from 'react'
import styles from './style.module.less'
import { UploadIcon } from '@/assets'
import { useTranslation } from 'react-i18next'
import { errorNotice, notice } from '@/common/tip'
import { CommonApi } from '@/services/commonAPI'
import classNames from 'classnames'

type Props = {
  images: string[]
  onChange: (urls: string[]) => void
}

const IMG_SIZE_LIMIT = 5 // 单位 M
const IMG_MAX_NUM = 3

const ImageUpload: React.FC<Props> = (props) => {
  const { images, onChange } = props
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement>(null)

  function checkFiles(files: File[]) {
    if (images.length + files.length > IMG_MAX_NUM) {
      notice(t('help.imgNumLimit'), 'warning')
      return false
    }
    if (files.some((file) => file.size > IMG_SIZE_LIMIT * 1024 * 1024)) {
      notice(t('help.imgSizeLimit'), 'warning')
      return false
    }
    return true
  }

  async function handleChange(e: any) {
    const files = Object.keys(e.target.files).map((key) => e.target.files[key])
    if (!checkFiles(files)) {
      e.target.value = ''
      return
    }
    const res = await CommonApi.fileUploadBatch(files)
    if (res.data.code === 0) {
      onChange([...images, ...res.data.data])
    } else {
      errorNotice(res.data.message)
    }
    e.target.value = ''
  }

  return (
    <div
      className={classNames(
        styles.uploadArea,
        images.length === IMG_MAX_NUM && styles.hidden
      )}
      onClick={() => ref.current?.click()}
    >
      <img src={UploadIcon} className={styles.uploadIcon} />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={ref}
        onChange={handleChange}
        accept={'image/*'}
        multiple
      />
    </div>
  )
}

export default React.memo(ImageUpload)
