import React from 'react'
import { ProfileTable } from '@/components/Table'
import { TableProps } from 'antd'
import { ellipsisText, formatTime, getLangData } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import { Order } from '@/services/payment'
import TextCopy from '@/components/TextCopy'

type Props = {
  dataSource: Order[]
}

const DiamondRechargeTable: React.FC<Props> = ({ dataSource }) => {
  const { t } = useTranslation()

  const columns: TableProps<Order>['columns'] = [
    {
      title: t('normal.orderId'),
      dataIndex: 'tradeNo',
      key: 'tradeNo',
      align: 'left',
      render(val) {
        return (
          <TextCopy text={val}>
            {ellipsisText(val, { head: 5, tail: 5 })}
          </TextCopy>
        )
      }
    },
    {
      title: t('normal.time'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
      render(val) {
        return formatTime(+val)
      }
    },
    {
      title: t('wallet.depositform.rechargeChannel'),
      dataIndex: 'chargeOrConsumeNameI18n',
      key: 'chargeOrConsumeNameI18n',
      align: 'center',
      render(val) {
        return getLangData(val)
      }
    },
    {
      title: t('normal.currency'),
      dataIndex: 'currency',
      key: 'currency',
      align: 'center'
    },
    {
      title: t('wallet.depositform.rechargeAmount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
    {
      title: t('wallet.depositform.diamondsReceived'),
      dataIndex: 'diamondAmount',
      key: 'diamondAmount',
      align: 'center'
    }
  ]

  return (
    <ProfileTable
      rowKey={'tradeNo'}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
    />
  )
}

export default React.memo(DiamondRechargeTable)
