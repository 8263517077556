import React, { useEffect } from 'react'
import styles from './style.module.less'
import { TextButton } from '@/components/Button'
import { useProof } from '@/hooks/web3/useProof'
import { errorNotice, notice, web3ErrorNotice } from '@/common/tip'
import { NFTRecord, ShopAPI } from '@/services/shopAPI'
import { useTranslation } from 'react-i18next'

type Props = {
  data: NFTRecord
  getList: () => void
}

const ReceiveBtn: React.FC<Props> = ({ data, getList }) => {
  const { t } = useTranslation()
  const { writeHandle, isLoading, isSuccess, hash } = useProof((error) => {
    web3ErrorNotice(error)
  })

  useEffect(() => {
    if (isSuccess && hash) {
      ShopAPI.notifyOrderState({
        txHash: hash,
        id: data.id
      }).then((res) => {
        if (res.data.code === 0) {
          notice(t('normal.receiveSuccess'), 'success')
          getList()
        } else {
          errorNotice(res.data.message)
        }
      })
    }
  }, [isSuccess, hash])

  return (
    <TextButton
      disabled={isLoading || data.state !== 1}
      onClick={() => {
        writeHandle(data)
      }}
      className={styles.receiveBtn}
    >
      {t('profile.receive')}
    </TextButton>
  )
}

export default React.memo(ReceiveBtn)
