import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { ButtonProps } from '@/common/types'

type Props = ButtonProps & {
  borderOnly?: boolean
  borderPink?: boolean
  loading?: boolean
  loadingText?: string
}

const Button = ({
  children,
  className,
  borderOnly = false,
  borderPink = false,
  loading = false,
  loadingText,
  ...rest
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      className={classNames(
        borderOnly && styles.borderOnly,
        styles.button,
        borderPink && styles.borderPink,
        className
      )}
      {...rest}
    >
      <div className={borderOnly ? styles.content : ''}>
        {loading ? loadingText || children : children}
      </div>
    </button>
  )
}

export default Button
