import React from 'react'
import TotalSkeleton from './Total/TotalSkeleton'
import AssetsDetailSkeleton from './AssetsDetail/AssetsDetailSkeleton'

const Web3AssetsSkeleton: React.FC = () => {
  return (
    <>
      <TotalSkeleton />
      <AssetsDetailSkeleton />
    </>
  )
}

export default Web3AssetsSkeleton
