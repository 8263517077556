import { errorNotice } from '@/common/tip'
import { useUserInfo } from '@/store/useUserInfo'
import { useAccount, useConnect, useDisconnect } from 'wagmi'

export default function useWalletConnect() {
  const { connectAsync, connectors } = useConnect()
  const metamaskConnector = connectors.find((c) => c.name === 'MetaMask')
  const okxConnector = connectors.find((c) => c.name === 'OKX Wallet')
  const { info } = useUserInfo()

  const { disconnect } = useDisconnect()
  const { isConnected } = useAccount() // 帐户信息

  function handleError(error: any) {
    errorNotice(error)
    if (isConnected) {
      disconnect()
    }
  }

  const onConnect = async () => {
    if (isConnected) {
      return true
    }
    const connector = info?.loginType === 4 ? okxConnector : metamaskConnector
    if (!connector) {
      return false
    }
    try {
      await connectAsync({ connector })
      return true
    } catch (error: any) {
      handleError(error)
      return false
    }
  }

  return { onConnect }
}
