import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { Space } from 'antd'
import Select from '@/components/Select'
import { PointIcon } from '@/assets'
import { ProfileButton } from '@/components/Button'
import { useHistory } from 'react-router-dom'
import VideoPreview from '../../components/VideoPreview'
import { HomemadeVideo, MediaAPI } from '@/services/MediaAPI'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import useQuery from '@/hooks/useQuery'
import { getStatus, getUserDrafts } from '@/common/dazzle'
import DraftFolder from '../DraftFolder'
import { useModal } from '@/store/useModal'
import VideoModal from '../../components/VideoModal'
import { useTranslation } from 'react-i18next'

const Creations: React.FC = () => {
  const [pageNo, setPageNo] = useState(1)
  const history = useHistory()
  const [type, setType] = useState(0)
  const { t } = useTranslation()

  const drafts = useMemo(() => {
    if (type === 0) {
      return getUserDrafts()
    }
    return []
  }, [type])

  const pageSize = drafts.length === 0 ? 12 : 11

  const { open } = useModal()

  const { data: totalPointsData } = useQuery(MediaAPI.getCreatorTotalPoints)

  const { data, refresh } = useQuery(
    () =>
      MediaAPI.getMyPageList({
        pageNo,
        pageSize,
        type
      }),
    [pageNo, type, pageSize]
  )

  const { records = [], total = 0 } = data || {}

  function onClick(videoInfo: HomemadeVideo) {
    const status = getStatus(videoInfo)
    if (status === 1 || status === 3) {
      history.push(`/dazzle-detail?id=${videoInfo.id}&manage=1`)
      return
    }
    open(<VideoModal refresh={refresh} videoInfo={videoInfo} />)
  }

  return (
    <div className={styles.creations}>
      <div className={styles.header}>
        <Space size={32}>
          <span>
            {t('dazzle.creations')} {+total + drafts.length}
          </span>
          <Select
            options={[
              { label: t('normal.all'), value: 0 },
              { label: t('dazzle.public'), value: 1 },
              { label: t('dazzle.underReview'), value: 2 },
              { label: t('dazzle.hidden'), value: 3 },
              { label: t('dazzle.delisted'), value: 4 },
              { label: t('dazzle.rejected'), value: 5 }
            ]}
            value={type}
            onChange={(val) => {
              setPageNo(1)
              setType(val as number)
            }}
            className={styles.select}
          />
        </Space>
        <Space size={25}>
          <div className={styles.reward}>
            <span>{t('dazzle.rewards')}</span>
            <img src={PointIcon} className={styles.pointIcon} />
            <span>{totalPointsData?.totalPoints ?? 0}</span>
          </div>
          {/* <ProfileButton
              onClick={() => history.push('/dazzle-upload')}
              className={styles.btn}
            >
              {t('dazzle.post')}
            </ProfileButton> */}
        </Space>
      </div>
      <Empty data={[...drafts, ...records]}>
        <div className={styles.list}>
          {drafts.length !== 0 && <DraftFolder />}
          {records.map((item) => {
            return <VideoPreview info={item} key={item.id} onClick={onClick} />
          })}
        </div>
      </Empty>
      <div className={styles.paginationCtn}>
        <Pagination
          total={+total}
          showSizeChanger={false}
          onChange={(page) => setPageNo(page)}
          current={pageNo}
          pageSize={pageSize}
        />
      </div>
    </div>
  )
}

export default React.memo(Creations)
