import React, { useState } from 'react'
import styles from './style.module.less'
import Select from '@/components/Select'
import LabelFormItem from '@/components/LabelFormItem'
import InputBox from '@/components/InputBox'
import Textarea from '@/components/Textarea'
import ImageUpload from '../components/ImageUpload'
import { getMediaSrc } from '@/utils/utils'
import { CloseIconFilled } from '@/assets'
import { Button } from '@/components/Button'
import { useTranslation } from 'react-i18next'
import { errorNotice, notice } from '@/common/tip'
import { CommonApi } from '@/services/commonAPI'

const { LimitInput } = InputBox

const Feedback: React.FC = () => {
  const { t } = useTranslation()
  const [images, setImages] = useState<string[]>([])
  const [eventType, setEventType] = useState(1)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  function handleDelete(index: number) {
    const newImages = images.filter((image, i) => i !== index)
    setImages(newImages)
  }

  async function submit() {
    if (!title) {
      notice(t('help.titlePlaceholder'), 'warning')
      return
    }
    if (!content) {
      notice(t('help.detailsPlaceholder'), 'warning')
      return
    }
    const params = {
      title,
      content,
      eventType,
      source: 1,
      fileUrls: images
    }
    const res = await CommonApi.feedback(params)
    if (res.data.code === 0) {
      notice(t('normal.submitSuccess'), 'success')
      setImages([])
      setEventType(1)
      setTitle('')
      setContent('')
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <div className={styles.feedback}>
      <LabelFormItem label={t('help.feedbackType')}>
        <Select
          value={eventType}
          onChange={(val) => setEventType(val as number)}
          placeholder={t('help.type')}
          options={[
            { label: t('help.type1'), value: 1 },
            { label: t('help.type2'), value: 2 },
            { label: t('help.type3'), value: 3 },
            { label: t('help.type4'), value: 4 }
          ]}
        />
      </LabelFormItem>
      <LabelFormItem label={t('help.title')}>
        <LimitInput
          limit={20}
          placeholder={t('help.titlePlaceholder')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </LabelFormItem>
      <LabelFormItem label={t('help.details')}>
        <Textarea
          limit={1000}
          placeholder={t('help.detailsPlaceholder')}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </LabelFormItem>
      <LabelFormItem label={t('help.addImage')}>
        <div className={styles.imgCtn}>
          {images.map((image, i) => {
            return (
              <div key={image} className={styles.imgPreview}>
                <img
                  src={CloseIconFilled}
                  className={styles.closeIcon}
                  onClick={() => handleDelete(i)}
                />
                <img src={getMediaSrc(image)} className={styles.img} />
              </div>
            )
          })}
          <ImageUpload images={images} onChange={(urls) => setImages(urls)} />
        </div>
      </LabelFormItem>
      <Button onClick={submit} className={styles.btn}>
        {t('normal.submit')}
      </Button>
    </div>
  )
}

export default React.memo(Feedback)
