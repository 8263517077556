import React, { useState } from 'react'
import styles from './style.module.less'
import { ProfileTable } from '@/components/Table'
import { DepositRecord } from '@/services/wallet'
import { TableProps } from 'antd'
import { formatTime, getLangData, getMediaSrc } from '@/utils/utils'
import { getTableCopyText } from '@/common/profile'
import { useTranslation } from 'react-i18next'
import { DownArrow } from '@/assets'
import classNames from 'classnames'

type Props = {
  dataSource: DepositRecord[]
}

const Table: React.FC<Props> = ({ dataSource }) => {
  const { t } = useTranslation()

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  function onExpand(id: string) {
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys((keys) => keys.filter((key) => key !== id))
    } else {
      setExpandedRowKeys((keys) => [...keys, id])
    }
  }

  const columns: TableProps<DepositRecord>['columns'] = [
    {
      title: t('profile.works'),
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 100,
      render(val, item) {
        return (
          <div className={styles.goodsNameCtn}>
            <img src={getMediaSrc(item.showImage)} className={styles.img} />
            <span>{getLangData(item.goodsNameI18n)}</span>
          </div>
        )
      }
    },
    {
      title: t('normal.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
    {
      title: t('profile.operate'),
      dataIndex: 'orderType',
      key: 'orderType',
      align: 'center',
      render(val, record) {
        return record.orderType === 3 || record.orderType === 1
          ? t('wallet.nft.deposit')
          : t('wallet.nft.withdraw')
      }
    },
    {
      title: t('normal.network') as string,
      dataIndex: 'chainCode',
      key: 'chainCode',
      align: 'center'
    },
    {
      title: t('normal.orderId') as string,
      dataIndex: 'txId',
      key: 'txId',
      render(val, record) {
        return (
          <div className={styles.orderIdCtn}>
            {getTableCopyText(record.txId, false)}
            <img
              src={DownArrow}
              className={classNames(
                styles.arrow,
                expandedRowKeys.includes(record.txId) && styles.expandedArrow
              )}
              onClick={() => onExpand(record.txId)}
            />
          </div>
        )
      }
    }
  ]

  function getAddress(record: DepositRecord) {
    if (record.orderType === 1 || record.orderType === 3) {
      // 充值
      return record.fromAddress
        ? getTableCopyText(record.fromAddress, true, record.fromAddressUrl)
        : getTableCopyText(record.otherSideUserCode, false)
    }
    return record.toAddress
      ? getTableCopyText(record.toAddress, true, record.toAddressUrl)
      : getTableCopyText(record.otherSideUserCode, false)
  }

  return (
    <div className={styles.tableCtn}>
      <ProfileTable
        rowKey={'txId'}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        expandedRowKeys={expandedRowKeys}
        expandable={{
          expandIcon() {
            return null
          },
          expandedRowRender(record) {
            return (
              <div className={styles.extraRow}>
                <div className={styles.item}>
                  <span className={styles.label}>
                    {record.orderType === 1 || record.orderType === 3
                      ? t('wallet.from')
                      : t('wallet.to')}
                    ：
                  </span>
                  <span>{getAddress(record)}</span>
                </div>
                <div className={styles.item}>
                  <span className={styles.label}>TXID：</span>
                  <span>{getTableCopyText(record.txHash)}</span>
                </div>
                <div className={styles.item}>
                  <span className={styles.label}>{t('normal.time')}：</span>
                  <span>{formatTime(+record.orderCreateTime)}</span>
                </div>
                <div className={styles.item}>
                  <span className={styles.label}>
                    {t('wallet.assets.networkFee')}：
                  </span>
                  <span>
                    {record.fee ? `${record.fee} ${record.feeTokenName}` : '-'}
                  </span>
                </div>
              </div>
            )
          }
        }}
      />
    </div>
  )
}

export default React.memo(Table)
